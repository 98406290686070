import { isSameDay, parse } from 'date-fns';

import { RescheduleAppointmentFormState, ScheduleAvailability } from '../RescheduleAppointmentForm.types';
import { Schedule } from 'api/types/schedule';
import { sortAvailableTime } from 'utils/sort/sort';

import {
  RescheduleAppointmentFormActions,
  SET_DATE,
  SET_TIME,
  SET_TIME_LIST,
  SET_VIEW_INDEX,
  SET_LOADING,
  SET_PRICES_FETCHED,
} from './RescheduleAppointmentFormReducer.types';

export function RescheduleAppointmentFormReducer(
  state: RescheduleAppointmentFormState,
  action: RescheduleAppointmentFormActions,
): RescheduleAppointmentFormState {
  switch (action.type) {
    case SET_DATE:
      if (!state.selectedDate || !isSameDay(action.payload.selectedDate, state.selectedDate)) {
        return {
          ...state,
          selectedDate: action.payload.selectedDate,
          selectedTime: undefined,
          isLoading: true,
          isPriceFetching: !state.isEstimate,
        };
      }
      return state;

    case SET_PRICES_FETCHED:
      return {
        ...state,
        isPriceFetching: false,
        isCallRequired: action.payload.isCallRequired,
      };

    case SET_TIME:
      const selectedTime =
        state.selectedTime && isSameSchedule(action.payload.selectedTime, state.selectedTime)
          ? undefined
          : action.payload.selectedTime;
      Object.entries(state.availabilityByPeriod).map(([key, schedule]) =>
        schedule.forEach(time => {
          time.selected = selectedTime && isSameSchedule(time, selectedTime);
        }),
      );
      return {
        ...state,
        selectedTime: selectedTime,
        selectedResourceId: selectedTime?.resourceId,
      };

    case SET_TIME_LIST:
      const scheduleAvailability: ScheduleAvailability = { morning: [], afternoon: [], evening: [] };
      action.payload.availability
        .slice()
        .sort(sortAvailableTime)
        .forEach(({ schedule, resource }) => {
          const available: Schedule = {
            period: schedule.period,
            start: parse(schedule.start, 'yyyy-MM-dd HH:mm:ss', new Date()),
            end: parse(schedule.end, 'yyyy-MM-dd HH:mm:ss', new Date()),
            friendly: schedule.friendly,
            resourceId: resource.id,
          };
          scheduleAvailability[schedule.period].push(available);
        });

      Object.entries(scheduleAvailability).map(([key, schedule]) =>
        schedule.forEach(time => {
          time.selected = state.selectedTime && isSameSchedule(time, state.selectedTime);
        }),
      );
      return {
        ...state,
        availability: action.payload.availability,
        availabilityByPeriod: scheduleAvailability,
        isLoading: false,
      };

    case SET_VIEW_INDEX:
      return {
        ...state,
        viewIndex: action.payload.viewIndex,
      };

    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    default:
      throw new Error();
  }
}

const isSameSchedule = (schedule1: Schedule, schedule2: Schedule) => {
  return schedule1.friendly === schedule2.friendly && schedule1.period === schedule2.period;
};
