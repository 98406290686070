import { makeStyles } from '@material-ui/core';

import background from 'assets/images/login-bg.png';

export const useStyles = makeStyles({
  root: {
    height: '100%',
  },
  content: {
    padding: '20px',
  },
  image: {
    height: '100%',
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${background})`,
  },
  alert: {
    width: '600px',
    maxWidth: '80%',
    '& .MuiAlert-message': {
      flex: '1',
      textAlign: 'center',
    },
    '& .MuiAlert-action': {
      paddingLeft: '0',
    },
  },
});
