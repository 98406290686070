import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

import { AppointmentsTableProps } from '../AppointmentsTableContainer.types';
import { AppointmentCell } from '../appointmentCell/AppointmentCell';
import { AppointmentDetails } from 'app/appointmentDetails/AppointmentDetails';
import { useStyles } from '../AppointmentsTableContainer.styles';
import { isCategorySupported } from 'utils/isCategorySupported/isCategorySupported';

export const HorizontalTable = ({
  appointments,
  columns,
  onExpand,
  expandedItemId,
  isFinished,
  isSingleAppointment,
}: AppointmentsTableProps) => {
  const styles = useStyles();
  return (
    <TableContainer className={clsx(styles.container, styles.horizontalTable)}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell key={column.name} className={styles.headerCell}>
                {column.displayName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={styles.body}>
          {appointments.map(appointment => (
            <React.Fragment key={appointment.id}>
              <TableRow
                id={`appointment-${appointment.id}`}
                className={styles.row}
                onClick={() => onExpand(appointment.id)}
              >
                {columns.map(column => (
                  <AppointmentCell
                    className={clsx(styles.bodyCell, expandedItemId === appointment.id && styles.iconCellExpanded)}
                    key={column.name}
                    appointment={appointment}
                    column={column.name}
                    onExpand={onExpand}
                  />
                ))}
              </TableRow>
              {(expandedItemId === appointment.id || isSingleAppointment) && (
                <AppointmentDetails
                  appointment={appointment}
                  isFinished={isFinished}
                  showManageButtons={
                    !isFinished && !isSingleAppointment && isCategorySupported(appointment.category.id)
                  }
                />
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
