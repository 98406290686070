import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { Scrollbars } from 'react-custom-scrollbars';

import { Button } from 'ui/button/Button';
import { Loader } from 'ui/loader/Loader';

import { TimePickerProps } from './TimePicker.types';
import { useStyles } from './TimePicker.styles';

export function TimePicker({ period, schedule, isLoading, onTimeSelected }: TimePickerProps) {
  const styles = useStyles();
  return (
    <Grid container direction="column" className={styles.container}>
      <Grid item xs="auto">
        <Typography variant="body2" color="primary" className={styles.title}>
          {period}
        </Typography>
      </Grid>
      <Grid item xs className={styles.timeContainer}>
        {isLoading && <Loader />}
        {!isLoading && schedule.length !== 0 && (
          <Scrollbars autoHide autoHideTimeout={800} autoHideDuration={200}>
            {schedule.map((time, index) => (
              <div className={styles.timeButton} key={`${index}-${time.period}`}>
                <Button
                  className={clsx(time.selected && 'selected')}
                  fullWidth
                  buttonType="twoTone"
                  onClick={() => onTimeSelected(time)}
                >
                  {time.friendly}
                </Button>
              </div>
            ))}
          </Scrollbars>
        )}
        {!isLoading && schedule.length === 0 && (
          <Typography variant="body1" className={styles.noAvailability}>
            No Availability
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}
