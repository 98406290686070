import { colors } from '@chhjpackages/components';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  tooltip: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.basic.white,
    color: colors.grey180,
    margin: 0,
    maxWidth: 'initial',
    boxShadow: '0px 4px 30px rgb(0 0 0 / 15%) ',
  },
  tooltipArrow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    padding: 16,
    top: '50%',
  },
  arrow: {
    fontSize: '1em',

    '&:before': {
      color: colors.basic.white,
    },
  },
}));
