import { colors as colorsCHHJ, NoahFont } from '@chhjpackages/components';
import { fade, makeStyles } from '@material-ui/core';

import { colors } from 'assets/theme/palette';

export const useStyles = makeStyles({
  formContainer: {
    minHeight: 122,
    position: 'relative',
    '& .sq-creditcard': {
      background: colors.orange,
      padding: '6px 16px',
      fontSize: '18px',
      minWidth: '64px',
      boxSizing: 'border-box',
      transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      fontFamily: 'Rift Soft',
      fontWeight: 'bold',
      lineHeight: '1.75',
      borderRadius: '4px',
      textTransform: 'uppercase',
      outline: 'none',
      '&:hover, &:focus': {
        background: colors.darkOrange,
      },
    },
    '& .sq-card-message': {
      minHeight: '24px',
      fontFamily: `${NoahFont}!important`,
      fontWeight: 'normal',
      margin: '0px!important',
      '&:before': {
        display: 'none!important',
      },
    },
    '& .c-cZYkIm': {
      height: 98,
    },
    '& #card-container': {
      minHeight: '122px!important',
    },
  },
  submitButtonContainer: {
    display: 'none',
  },
});

export const creditCardInputStyles = {
  input: {
    fontFamily: 'sans-serif',
    color: colorsCHHJ.basic.black,
  },
  'input.is-error': {
    color: colorsCHHJ.functionals.alert,
  },
  '.input-container': {
    borderRadius: '0px',
    borderWidth: '0.625px',
    borderColor: colorsCHHJ.grey30,
  },
  '.input-container.is-error': {
    borderWidth: '0.625px',
    borderColor: colorsCHHJ.functionals.alert,
  },
  '.input-container.is-focus': {
    borderWidth: '0.625px',
    borderColor: colorsCHHJ.primary.main,
  },
  '.message-text': {
    color: colorsCHHJ.grey140,
  },
  '.message-text.is-error': {
    color: colorsCHHJ.functionals.alert,
  },
  '.message-icon.is-error': {
    color: colorsCHHJ.functionals.alert,
  },
  '.message-icon': {
    color: colorsCHHJ.grey140,
  },
  'input.is-focus': {
    color: colorsCHHJ.basic.black,
  },
  'input::placeholder': {
    color: fade(colorsCHHJ.grey190, 0.6),
  },
};
