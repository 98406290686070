import { makeStyles } from '@material-ui/core';

import { colors } from 'assets/theme/palette';

export const useStyles = makeStyles(theme => ({
  dialogContainer: {
    textAlign: 'center',
    overflowY: 'scroll',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  subtitle: {
    color: colors.orange,
  },
  checkIcon: {
    fontSize: '6rem',
    filter: `drop-shadow(5px 4px 0px ${colors.green}55)`,
  },
  dialogDescription: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  dialogHeader: {
    width: '100%',
  },
}));
