import { Grid, Typography } from '@material-ui/core';
import React from 'react';

import { displayDate } from 'utils/formatDate/FormatDate';

import { useStyles } from './ReviewAppointmentDialog.styles';
import { ReviewAppointmentDialogProps } from './ReviewAppointmentDialog.types';
import { ReviewAppointmentFaces } from './reviewAppointmentFaces/ReviewAppointmentFaces';

export function ReviewAppointmentDialog({ appointment }: ReviewAppointmentDialogProps) {
  const styles = useStyles();

  const arrayToSentence = (a: string[]) =>
    [a.slice(0, -1).join(', '), a[a.length - 1]].filter(w => w !== '').join(' and ');
  let hunks_string = '';
  if (appointment.assignedHunks.length > 0) {
    hunks_string = ' with ' + arrayToSentence(appointment.assignedHunks);
  }

  return (
    <Grid container direction="row" alignItems="center" spacing={2} className={styles.dialogContainer}>
      <Grid item className={styles.dialogHeader}>
        <Typography style={{ color: 'grey' }} variant="h4">
          On <span className={styles.subtitle}>{displayDate(appointment.startDate)}</span>, you had a{' '}
          {appointment.category.name} service {hunks_string}
        </Typography>
        <Typography variant="h3">HOW LIKELY IS IT THAT YOU WOULD RECOMMEND US TO A FRIEND OR COLLEAGUE?</Typography>
      </Grid>

      <Grid item container>
        <ReviewAppointmentFaces appointment={appointment} />
      </Grid>
    </Grid>
  );
}
