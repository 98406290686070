import { RescheduleAppointmentFormState } from '../RescheduleAppointmentForm.types';

export const SET_TIME_LIST = 'SET_TIME_LIST';
export const SET_DATE = 'SET_DATE';
export const SET_TIME = 'SET_TIME';
export const SET_RESOURCE_ID = 'SET_RESOURCE_ID';
export const SET_VIEW_INDEX = 'SET_VIEW_INDEX';
export const SET_LOADING = 'SET_LOADING';
export const SET_PRICES_FETCHED = 'SET_PRICES_FETCHED';

export type SetTimeListType = typeof SET_TIME_LIST;
export type SetDateType = typeof SET_DATE;
export type SetTimeType = typeof SET_TIME;
export type SetResourceIdType = typeof SET_RESOURCE_ID;
export type SetViewIndexType = typeof SET_VIEW_INDEX;
export type SetLoadingType = typeof SET_LOADING;
export type SetPricesFetchedType = typeof SET_PRICES_FETCHED;

export type Action<Type, Payload> = {
  type: Type;
  payload: Payload;
};

export type SetTimeListAction = Action<SetTimeListType, TimeListActionPayload>;
export type SetDateAction = Action<SetDateType, DateActionPayload>;
export type SetTimeAction = Action<SetTimeType, TimeActionPayload>;
export type SetResourceIdAction = Action<SetResourceIdType, ResourceIdActionPayload>;
export type SetViewIndexAction = Action<SetViewIndexType, ViewIndexActionPayload>;
export type SetLoadingAction = Action<SetLoadingType, LoadingActionPayload>;
export type SetPricesFetchedAction = Action<SetPricesFetchedType, PricesFetchedActionPayload>;

export type TimeListActionPayload = Pick<RescheduleAppointmentFormState, 'availability'>;
export type DateActionPayload = Pick<RescheduleAppointmentFormState, 'selectedDate'>;
export type TimeActionPayload = Required<Pick<RescheduleAppointmentFormState, 'selectedTime'>>;
export type ResourceIdActionPayload = Required<Pick<RescheduleAppointmentFormState, 'selectedResourceId'>>;
export type ViewIndexActionPayload = Pick<RescheduleAppointmentFormState, 'viewIndex'>;
export type LoadingActionPayload = Pick<RescheduleAppointmentFormState, 'isLoading'>;
export type PricesFetchedActionPayload = Pick<RescheduleAppointmentFormState, 'isCallRequired'>;

export type RescheduleAppointmentFormActions =
  | SetTimeListAction
  | SetDateAction
  | SetTimeAction
  | SetResourceIdAction
  | SetViewIndexAction
  | SetLoadingAction
  | SetPricesFetchedAction;
