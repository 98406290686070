import { AppointmentsStateType } from '../appointmentsContext/AppointmentsContext.types';

export const SET_APPOINTMENTS = 'SET_APPOINTMENTS';
export const SET_RELOAD_APPOINTMENTS = 'SET_RELOAD_APPOINTMENTS';

export type SetAppointments = typeof SET_APPOINTMENTS;
export type SetReloadAppointments = typeof SET_RELOAD_APPOINTMENTS;

export type SimpleAction<Type> = {
  type: Type;
};

export type Action<Type, Payload> = SimpleAction<Type> & {
  payload: Payload;
};

export type SetAppointmentsAction = Action<SetAppointments, AppointmentsPayload>;
export type SetReloadAppointmentsAction = SimpleAction<SetReloadAppointments>;

export type AppointmentsPayload = Pick<
  AppointmentsStateType,
  'appointments' | 'upcomingAppointments' | 'finishedAppointments'
>;

export type AppointmentsContextActions = SetAppointmentsAction | SetReloadAppointmentsAction;
