import {
  AddressAutocomplete,
  AddressAutocompleteOnSelectValues,
  Input,
  NewSelect,
  SelectOptionType,
} from '@chhjpackages/components';
import { FormControl, FormHelperText, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';

import { getValidationText } from 'utils/formHelpers/getValidationText';
import { getSelectValue } from 'utils/formHelpers/getSelectValue';

import { AccountDataFormValues, AccountDataFormProps } from './AccountDataForm.types';
import { accountDataFormSchema } from './AccountDataForm.utils';

export const useAccountDataForm = ({ addressOptions, defaultValues }: AccountDataFormProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    control,
    formState: { isValid },
    errors: formErrors,
    register,
    watch,
    setValue,
    getValues,
  } = useForm<AccountDataFormValues>({
    mode: 'onChange',
    validationSchema: accountDataFormSchema,
    defaultValues: defaultValues,
  });

  const stateWatched = watch('state');
  const countryWatched = watch('country');

  const countryOptions = useMemo(() => {
    return addressOptions.map(addr => ({
      label: addr.countryName,
      value: addr.countryValue,
    }));
  }, [addressOptions]);

  const stateOptions = useMemo(() => {
    return addressOptions.map(addr => ({
      label: addr.countryName,
      options: addr.states.map(state => ({
        label: state.name,
        value: state.value,
      })),
    }));
  }, [addressOptions]);

  const onAddressAutocompleteSelect = useCallback(
    (values: AddressAutocompleteOnSelectValues | null) => {
      setValue('address', values?.fullAddress.address ?? '', true);

      if (!values) {
        return;
      }

      setValue('address', values.fullAddress.address ?? '', true);
      setValue('city', values.fullAddress.city ?? '', true);
      setValue('state', values.fullAddress.state ?? '', true);
      setValue('postal', values.fullAddress.postal ?? '', true);
      setValue('country', values.fullAddress.country ?? '', true);
    },
    [setValue],
  );

  useEffect(() => {
    register({ name: 'address' });
    register({ name: 'state' });
    register({ name: 'country' });
  }, []);

  const renderAccountDataForm = () => (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Grid container spacing={isMobile ? 2 : 3}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!formErrors.firstName}>
              <Controller
                name="firstName"
                control={control}
                as={<Input variant="outlined" label="First name" error={!!formErrors.firstName} size="medium" />}
              />
              <FormHelperText>{getValidationText(formErrors, 'firstName')}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!formErrors.lastName}>
              <Controller
                name="lastName"
                control={control}
                as={<Input variant="outlined" label="Last name" error={!!formErrors.lastName} size="medium" />}
              />
              <FormHelperText>{getValidationText(formErrors, 'lastName')}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <FormControl fullWidth error={!!formErrors.address}>
          <AddressAutocomplete
            label="Address"
            name="address"
            inputValue={defaultValues?.address ?? ''}
            hasError={!!formErrors.address}
            onSelect={onAddressAutocompleteSelect}
            onBlur={() => {}}
          />
          <FormHelperText>{getValidationText(formErrors, 'address')}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl fullWidth error={!!formErrors.address2}>
          <Controller
            name="address2"
            control={control}
            as={<Input variant="outlined" label="Suite/apt" error={!!formErrors.address2} size="medium" />}
          />
        </FormControl>
      </Grid>
      <Grid item>
        <Grid container spacing={isMobile ? 2 : 3}>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth error={!!formErrors.city}>
              <Controller
                name="city"
                control={control}
                as={<Input variant="outlined" label="City" error={!!formErrors.city} size="medium" />}
              />
              <FormHelperText>{getValidationText(formErrors, 'city')}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <NewSelect
                label="State"
                name="state"
                options={stateOptions}
                value={getSelectValue(stateWatched, stateOptions)}
                onChange={(val: SelectOptionType<string>) => setValue('state', val.value, true)}
              />
              <FormHelperText>{getValidationText(formErrors, 'state')}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth error={!!formErrors.postal}>
              <Controller
                name="postal"
                control={control}
                as={<Input variant="outlined" label="Zip/postal" error={!!formErrors.postal} size="medium" />}
              />
              <FormHelperText>{getValidationText(formErrors, 'postal')}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={isMobile ? 2 : 3}>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <NewSelect
                label="Country"
                name="country"
                options={countryOptions}
                value={getSelectValue(countryWatched, countryOptions)}
                onChange={(val: SelectOptionType<string>) => setValue('country', val.value, true)}
              />
              <FormHelperText>{getValidationText(formErrors, 'country')}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return { isValid, errors: formErrors, renderAccountDataForm, getValues };
};
