import React, { useState, useEffect } from 'react';
import { useMediaQuery, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { Loader } from 'ui/loader/Loader';
import { HighHouseIcon } from 'assets/icons/HighHouseIcon';

import { AppointmentsTableColumn, AppointmentsTableContainerProps } from './AppointmentsTableContainer.types';
import { useStyles } from './AppointmentsTableContainer.styles';
import { HorizontalTable } from './appointmentsTableHorizontal/AppointmentsTableHorizontal';
import { VerticalTable } from './appointmentsTableVertical/AppointmentsTableVertical';

export const AppointmentsTableContainer = ({
  appointments,
  isFinished,
  isLoading,
  isSingleAppointment,
  expandedItemId,
}: AppointmentsTableContainerProps) => {
  const styles = useStyles();
  const isVertical = useMediaQuery('(max-width:960px)');

  const columns: AppointmentsTableColumn[] = [
    { name: 'id', displayName: 'ID' },
    { name: 'startDate', displayName: 'Start date/Time' },
    { name: 'endDate', displayName: 'End date/Time' },
    { name: 'category', displayName: 'Category' },
    { name: 'type', displayName: 'type' },
    ...(isFinished ? [{ name: 'status', displayName: 'status' }] : []),
    { name: 'expand', displayName: '' },
  ];

  const [expandedId, setExpandedId] = useState<number>(0);
  const handleExpand = (id: number) => {
    !isSingleAppointment && setExpandedId(id !== expandedId ? id : 0);
  };

  useEffect(() => {
    if (expandedItemId) {
      setExpandedId(expandedItemId);
    }
  }, [expandedItemId]);

  return (
    <div
      className={clsx(styles.root, isFinished && styles.finished, isSingleAppointment && styles.single)}
      data-testid="appointments-table"
    >
      {!isLoading ? (
        appointments.length ? (
          !isVertical ? (
            <HorizontalTable
              appointments={appointments}
              columns={columns}
              onExpand={handleExpand}
              expandedItemId={expandedId}
              isFinished={!!isFinished}
              isSingleAppointment={!!isSingleAppointment}
            />
          ) : (
            <VerticalTable
              appointments={appointments}
              columns={columns}
              onExpand={handleExpand}
              expandedItemId={expandedId}
              isFinished={!!isFinished}
              isSingleAppointment={!!isSingleAppointment}
            />
          )
        ) : (
          <Grid className={styles.noTable} container item direction="column" justify="center" alignItems="center">
            <HighHouseIcon className={styles.emptyIcon} />
            <Typography variant="body1" className={styles.emptyText}>
              No {!isFinished ? 'upcoming' : 'finished'} appointments
            </Typography>
          </Grid>
        )
      ) : (
        <Grid className={styles.noTable} container item direction="column" justify="center" alignItems="center">
          <Loader />
        </Grid>
      )}
    </div>
  );
};
