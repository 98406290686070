const TOKEN_KEY = 'accessToken';
const EXPIRES_KEY = 'expires';
const ACCOUNT_ID_KEY = 'accountId';
const LOCATION_ID_KEY = 'locationId';

class AuthStorage {
  private _token: string | null = null;
  private _expires: number | null = null;
  private _accountId: number | null = null;
  private _locationId: number | null = null;

  constructor() {
    try {
      this.token = sessionStorage.getItem(TOKEN_KEY);
      this.expires = Number(sessionStorage.getItem(EXPIRES_KEY));
      this.accountId = Number(sessionStorage.getItem(ACCOUNT_ID_KEY));
      this.locationId = Number(sessionStorage.getItem(LOCATION_ID_KEY));
    } catch (error) {
      this.token = null;
      this.expires = null;
      this.accountId = null;
      this.locationId = null;
    }
  }

  get token(): string | null {
    return this._token;
  }

  set token(value: string | null) {
    this._token = value;

    try {
      if (typeof value === 'string') {
        sessionStorage.setItem(TOKEN_KEY, value);
      } else {
        sessionStorage.removeItem(TOKEN_KEY);
      }
    } catch (error) {}
  }

  get accountId(): number | null {
    return this._accountId;
  }

  set accountId(value: number | null) {
    this._accountId = value;

    try {
      if (typeof value === 'number') {
        sessionStorage.setItem(ACCOUNT_ID_KEY, value.toString());
      } else {
        sessionStorage.removeItem(ACCOUNT_ID_KEY);
      }
    } catch (error) {}
  }

  get locationId(): number | null {
    return this._locationId;
  }

  set locationId(value: number | null) {
    this._locationId = value;

    try {
      if (typeof value === 'number') {
        sessionStorage.setItem(LOCATION_ID_KEY, value.toString());
      } else {
        sessionStorage.removeItem(LOCATION_ID_KEY);
      }
    } catch (error) {}
  }

  get expires(): number | null {
    return this._expires;
  }

  set expires(value: number | null) {
    this._expires = value;

    try {
      if (typeof value === 'number') {
        sessionStorage.setItem(EXPIRES_KEY, value.toString());
      } else {
        sessionStorage.removeItem(EXPIRES_KEY);
      }
    } catch (error) {}
  }
}

export const authStorage = new AuthStorage();
