import React from 'react';
import { Dialog as MaterialDialog, DialogContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { Button } from 'ui/button/Button';

import { useStyles } from './Dialog.styles';
import { DialogProps } from './Dialog.types';

export function Dialog({ open, onClose, children }: DialogProps) {
  const styles = useStyles();
  return (
    <MaterialDialog open={open} onClose={onClose} className={styles.dialog} maxWidth="sm">
      <DialogContent className={styles.dialogContent}>
        <div className={styles.close}>
          <Button buttonType="twoTone" size="medium" onClick={onClose}>
            <CloseIcon />
          </Button>
        </div>
        {children}
      </DialogContent>
    </MaterialDialog>
  );
}
