import { Grid, ListItem as ListItemMui, Typography } from '@material-ui/core';

import { useStyles } from './ListItem.styles';
import { ListItemProps } from './ListItem.types';

export const ListItem = ({ title, description }: ListItemProps) => {
  const styles = useStyles();

  return (
    <ListItemMui>
      <Grid container spacing={2} wrap="nowrap" justify="space-between">
        <Grid item>
          <Typography variant="body1" className={styles.title}>
            {title}
          </Typography>
        </Grid>
        {description && (
          <Grid item>
            <Typography variant="body1" className={styles.description}>
              {description}
            </Typography>
          </Grid>
        )}
      </Grid>
    </ListItemMui>
  );
};
