import React from 'react';
import { InputLabel } from '@material-ui/core';

import { InputStyled, useStyles } from './Input.styles';
import { InputProps } from './Input.types';
import { PhoneMask } from './inputMasks/PhoneMask';
import { DollarAmountMask } from './inputMasks/DollarAmountMask';

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ size = 'large', label, placeholder, mask, ...props }: InputProps, ref) => {
    const styles = useStyles(size);

    return (
      <div className={styles.root}>
        {label && <InputLabel htmlFor={props.name}>{label}</InputLabel>}
        {!mask && (
          <InputStyled
            placeholder={!label ? placeholder : ''}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            ref={ref}
          />
        )}
        {mask === 'phone' && (
          <InputStyled
            placeholder={!label ? placeholder : ''}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputComponent={PhoneMask as any}
            ref={ref}
          />
        )}
        {mask === 'dollars' && (
          <InputStyled
            placeholder={!label ? placeholder : ''}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputRef={ref}
            ref={ref}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputComponent={DollarAmountMask as any}
          />
        )}
      </div>
    );
  },
);
