import React from 'react';

import { MessagesProvider, MessagesContextState, MessagesContextDispatch } from 'context/messages/MessagesContext';

const useMessagesState = () => {
  const context = React.useContext(MessagesContextState);

  if (context === undefined) {
    throw new Error('useMessages must be used within a MessagesProvider');
  }

  return context;
};

const useMessagesDispatch = () => {
  const context = React.useContext(MessagesContextDispatch);

  if (context === undefined) {
    throw new Error('useSetMessages must be used within a MessagesProvider');
  }

  return context;
};

export { MessagesProvider, useMessagesState, useMessagesDispatch };
