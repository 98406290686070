import { makeStyles } from '@material-ui/core';

import { theme } from 'assets/theme/Theme';
import { colors } from 'assets/theme/palette';

export const useStyles = makeStyles({
  gridContainer: {
    height: '100%',
  },
  gridItem: {
    width: '400px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    textAlign: 'center',
  },
  title: {},
  description: {
    marginTop: '24px',
  },
  email: {
    fontWeight: 'bold',
  },
  icon: {
    width: '107px',
    height: '107px',
    [theme.breakpoints.down('sm')]: {
      width: '75px',
      height: '75px',
    },
  },
  resendMessage: {
    marginTop: '75px',
  },
  resendButton: {
    textTransform: 'none',
    textDecoration: 'underline',
    color: colors.orange,
  },
});
