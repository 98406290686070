import React from 'react';

import { AspectRatio } from '../aspectRatio/AspectRatio';

import { ImageStyled } from './Image.styles';
import { ImageProps } from './Image.types';

export const Image = ({ src, alt, className, ratio, onLoad }: ImageProps) => {
  const content = <ImageStyled className={className} src={src} alt={alt} onLoad={onLoad} />;

  if (!!ratio) {
    return <AspectRatio ratio={ratio}>{content}</AspectRatio>;
  }

  return <>{content}</>;
};
