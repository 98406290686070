import AdyenCheckout from '@adyen/adyen-web';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CoreOptions } from '@adyen/adyen-web/dist/types/core/types';

import { useHunkPayState } from 'hooks/useHunkPay/useHunkPay';

import { cardStyles } from './HunkPayForm.styles';
import { HunkPayFormProps } from './HunkPayForm.types';

import '@adyen/adyen-web/dist/adyen.css';
import 'assets/styles/ayden.css';

export const useHunkPayForm = ({ shouldRender }: HunkPayFormProps) => {
  const { paymentMethods, isLoading: paymentMethodsLoading } = useHunkPayState();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [encryptedCardDetails, setEncryptedCardDetails] = useState<any>();
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState({
    cardNumber: false,
    expirationDate: false,
    cvv: false,
  });

  const paymentContainer = useRef<HTMLDivElement | null>(null);

  const handleOnChange: CoreOptions['onChange'] = state => {
    setErrors({
      cardNumber: !state?.valid?.encryptedCardNumber,
      expirationDate: !state?.valid?.encryptedExpiryMonth || !state?.valid?.encryptedExpiryYear,
      cvv: !state?.valid?.encryptedSecurityCode,
    });

    if (state.isValid) {
      setIsValid(true);
      setEncryptedCardDetails(state.data);
    } else {
      setIsValid(false);
      setEncryptedCardDetails(null);
    }
  };

  const configuration: CoreOptions = useMemo(
    () => ({
      locale: 'en_US',
      environment: process.env.REACT_APP_HUNKPAY_ENV,
      clientKey: process.env.REACT_APP_HUNKPAY_CLIENT_KEY,
      analytics: {
        enabled: true,
      },
      paymentMethodsResponse: { paymentMethods: paymentMethods },
      paymentMethodsConfiguration: {
        card: {
          styles: cardStyles,
        },
      },
      translations: {
        'en-US': {
          'creditCard.numberField.title': 'Credit card number',
          'creditCard.expiryDateField.title': 'Expiration date',
          'creditCard.cvcField.title': 'CVV',
        },
      },
      onChange: handleOnChange,
    }),
    [paymentMethods],
  );

  const createCheckout = useCallback(async () => {
    const checkout = await AdyenCheckout(configuration);

    if (paymentContainer.current) {
      checkout.create('card').mount(paymentContainer.current);
    }
  }, [configuration]);

  const renderHunkPayForm = useCallback(
    () => (
      <>
        <div ref={paymentContainer} />
      </>
    ),
    [],
  );

  useEffect(() => {
    if (!paymentMethodsLoading && shouldRender) {
      createCheckout();
    }
  }, [paymentMethodsLoading, shouldRender]);

  return {
    isValid,
    errors,
    encryptedCardDetails,
    renderHunkPayForm,
  };
};
