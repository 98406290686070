import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import React, { useEffect, useReducer, useCallback } from 'react';
import { Grid, IconButton, Hidden, Typography } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import clsx from 'clsx';

import { Button } from 'ui/button/Button';
import { TimePicker } from 'ui/timePicker/TimePicker';
import { Calendar } from 'ui/calendar/Calendar';
import { Schedule } from 'api/types/schedule';
import { Prices } from 'app/prices/Prices';
import { displayDate } from 'utils/formatDate/FormatDate';
import { useMessagesDispatch } from 'hooks/useMessages/useMessages';

import { RescheduleAppointmentFormProps } from './RescheduleAppointmentForm.types';
import { useStyles } from './RescheduleAppointmentForm.styles';
import {
  SET_VIEW_INDEX,
  SET_DATE,
  SET_TIME,
  SET_TIME_LIST,
  SET_PRICES_FETCHED,
} from './rescheduleAppointmentFormReducer/RescheduleAppointmentFormReducer.types';
import { RescheduleAppointmentFormReducer } from './rescheduleAppointmentFormReducer/RescheduleAppointmentFormReducer';

export function RescheduleAppointmentForm({
  onSubmit,
  onCheckAvailability,
  notAvailable,
  appointment,
  isDuringRequest,
}: RescheduleAppointmentFormProps) {
  const styles = useStyles();
  const setMessage = useMessagesDispatch();
  const [formState, setFormState] = useReducer(RescheduleAppointmentFormReducer, {
    availability: [],
    availabilityByPeriod: { morning: [], afternoon: [], evening: [] },
    isLoading: true,
    isPriceFetching: true,
    isCallRequired: false,
    isEstimate: appointment.type === 'EST',
    viewIndex: 0,
    selectedDate: appointment.startDate,
  });

  const handleDateChange = (date: Date) => {
    setFormState({ type: SET_DATE, payload: { selectedDate: date } });
  };

  const handleTimeSelected = (time: Schedule) => {
    setFormState({ type: SET_TIME, payload: { selectedTime: time } });
  };

  const handleViewIndexChange = (index: number) => {
    setFormState({ type: SET_VIEW_INDEX, payload: { viewIndex: index } });
  };

  const handlePricesFetch = ({ isCallRequired }: { isCallRequired: boolean }) => {
    setFormState({ type: SET_PRICES_FETCHED, payload: { isCallRequired: isCallRequired } });
  };

  const handleSubmit = () => {
    if (formState.selectedTime && formState.selectedResourceId) {
      onSubmit(formState.selectedTime.start, formState.selectedTime.end, formState.selectedResourceId);
    }
  };

  const checkAvailability = useCallback(
    async (date: Date) => {
      const timeList = await onCheckAvailability(date);
      setFormState({ type: SET_TIME_LIST, payload: { availability: timeList } });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    handleDateChange(appointment.startDate);
  }, [appointment.startDate]);

  useEffect(() => {
    if (formState.selectedDate && !formState.isPriceFetching) {
      checkAvailability(formState.selectedDate);
    }
  }, [formState.selectedDate, formState.isPriceFetching, checkAvailability]);

  useEffect(() => {
    if (notAvailable) {
      setMessage({ message: 'Chosen reschedule date is not available anymore!', type: 'error' });
      checkAvailability(formState.selectedDate);
    }
  }, [checkAvailability, formState.selectedDate, notAvailable, setMessage]);

  return (
    <>
      <Grid container className={styles.dateTime}>
        <Grid item xs={12} md="auto" className={styles.calendarContainer}>
          <Calendar onChange={date => handleDateChange(date as Date)} date={formState.selectedDate} />
        </Grid>
        <Grid item xs={12} md>
          {formState.isCallRequired ? (
            <Grid container alignItems="center" justify="center" className={styles.callRequired}>
              <Grid item xs>
                <Typography variant="body1">
                  Want to reschedule on date {displayDate(formState.selectedDate)}?
                </Typography>
                <Typography variant="body1" color="secondary">
                  Call us 833-626-1326!
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <>
              <Hidden mdUp>
                <Grid container className={styles.swipeContainer} alignItems="center">
                  <Grid item className={clsx(styles.arrow, styles.arrowLeft)}>
                    <IconButton
                      color="secondary"
                      disabled={formState.viewIndex === 0}
                      onClick={() => handleViewIndexChange(formState.viewIndex - 1)}
                      className={styles.iconButton}
                    >
                      <KeyboardArrowLeftRoundedIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs className={styles.swipeItem}>
                    <SwipeableViews
                      axis="x"
                      className={styles.swipe}
                      onChangeIndex={handleViewIndexChange}
                      index={formState.viewIndex}
                    >
                      {Object.entries(formState.availabilityByPeriod).map(([key, schedule]) => (
                        <TimePicker
                          key={key}
                          period={key}
                          schedule={schedule}
                          isLoading={formState.isLoading}
                          onTimeSelected={handleTimeSelected}
                        />
                      ))}
                    </SwipeableViews>
                  </Grid>
                  <Grid item className={clsx(styles.arrow, styles.arrowRight)}>
                    <IconButton
                      color="secondary"
                      disabled={formState.viewIndex === 2}
                      onClick={() => handleViewIndexChange(formState.viewIndex + 1)}
                      className={styles.iconButton}
                    >
                      <KeyboardArrowRightRoundedIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden smDown>
                <Grid container>
                  {Object.entries(formState.availabilityByPeriod).map(([key, schedule]) => (
                    <Grid key={key} item xs={12} md={4}>
                      <TimePicker
                        period={key}
                        schedule={schedule}
                        isLoading={formState.isLoading}
                        onTimeSelected={handleTimeSelected}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Hidden>
            </>
          )}
        </Grid>
      </Grid>
      {appointment.type !== 'EST' && (
        <Prices appointment={appointment} jobDate={formState.selectedDate} onPricesFetched={handlePricesFetch} />
      )}
      <div className={styles.buttonContainer}>
        <Button
          disabled={!formState.selectedTime}
          className={styles.button}
          buttonType="twoTone"
          size="large"
          onClick={() => handleSubmit()}
          isLoading={isDuringRequest}
        >
          Reschedule appointment
        </Button>
      </div>
    </>
  );
}
export type Reason = {
  id: number;
  displayName: string;
};
