import { CreditCardInput, SquarePaymentsForm } from 'react-square-web-payments-sdk';
import * as Square from '@square/web-sdk';
import { useCallback } from 'react';
import { useMediaQuery } from '@material-ui/core';

import { useStyles, creditCardInputStyles } from './SquareForm.styles';
import { SquarePaymentFormProps } from './SquareForm.types';

export const useSquareForm = ({ appointment, paymentAmount, onSubmitSquareForm }: SquarePaymentFormProps) => {
  const isSquareMobile = useMediaQuery('(max-width: 607px)', {
    noSsr: true,
  });

  const styles = useStyles({ isSquareMobile });

  const createVerificationDetails = useCallback(() => {
    const verificationDetails: Square.ChargeVerifyBuyerDetails | Square.StoreVerifyBuyerDetails = {
      billingContact: {
        givenName: appointment.user.first_name,
        familyName: appointment.user.last_name,
        email: appointment.user.email,
        countryCode: appointment.user.country,
        city: appointment.user.city,
        addressLines: [appointment.user.address, appointment.user.address2],
        postalCode: appointment.user.postal,
        phone: appointment.user.phone || appointment.user.cell,
      },
      amount: paymentAmount.toString(),
      currencyCode: 'USD',
      intent: 'CHARGE',
    };

    return verificationDetails;
  }, [appointment.user, paymentAmount]);

  const renderSquarePaymentForm = () => (
    <div className={styles.formContainer}>
      <SquarePaymentsForm
        applicationId={process.env.REACT_APP_SQUARE_APPLICATION_ID}
        locationId={appointment.squareLocationId}
        cardTokenizeResponseReceived={onSubmitSquareForm}
        createVerificationDetails={createVerificationDetails}
      >
        <CreditCardInput
          submitButtonId="square-submit-button"
          style={creditCardInputStyles}
          children={({ Button: SqButton }) => (
            <div className={styles.submitButtonContainer}>
              <SqButton />
            </div>
          )}
        />
      </SquarePaymentsForm>
    </div>
  );

  return { renderSquarePaymentForm };
};
