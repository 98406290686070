import { Action } from '../../types';

import { StatesRequest } from './StatesActions.types';

export const fetchStatesAction = (request: StatesRequest): Action<StatesRequest> => {
  const {
    path: { account_id },
    query: { token },
  } = request;

  return {
    method: 'GET',
    endpoint: `/public/website/${account_id}/states?token=${token}`,
  };
};
