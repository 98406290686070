import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';

import { colors } from 'assets/theme/palette';

export const useStyles = makeStyles(() => ({
  large: {
    width: 90,
    height: 63,
  },
}));

export const ToggleButtonStyled = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:focus': { background: `${colors.grey}1a` },
    },
    selected: {
      color: `${colors.white}!important`,
      background: `${colors.orange}!important`,
      '&:focus': { background: `${colors.darkOrange}!important` },
    },
  }),
)(ToggleButton);
