/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useCallback, useMemo } from 'react';
import { Collapse, Divider, Typography, useMediaQuery } from '@material-ui/core';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import * as Square from '@square/web-sdk';
import { colors } from '@chhjpackages/components';

import { Button } from 'ui/button/Button';
import { usePayment } from 'hooks/usePayment/usePayment';
import { Input } from 'ui/input/Input';
import { ToggleButton } from 'ui/toggleButton/ToggleButton';
import { useSquareForm } from 'hooks/useSquareForm/useSquareForm';
import { useHunkPayForm } from 'hooks/useHunkPayForm/useHunkPayForm';
import { useAccountDataForm } from 'hooks/useAccountDataForm/useAccountDataForm';
import { useAddressOptions } from 'hooks/useAddressOptions/useAddressOptions';
import { ListItem } from 'ui/listItem/ListItem';
import { parseAddress } from 'utils/address/parseAddress';
import { Tooltip } from 'ui/tooltip/Tooltip';
import { accountDataErrorLabels } from 'hooks/useAccountDataForm/AccountDataForm.utils';
import { hunkPayErrorLabels } from 'hooks/useHunkPayForm/HunkPayForm.utils';

import { ListStyled, useStyles } from './PaymentForm.styles';
import { PaymentFormProps, PaymentProviderEnum } from './PaymentForm.types';
import { tipOptions } from './PaymentForm.utils';
import { BillingInformationCard } from './billingInformationCard/BillingInformationCard';

export function PaymentForm({ appointment, paymentAmount, tipEnabled, onSuccess, onFailed }: PaymentFormProps) {
  const isVertical = useMediaQuery('(max-width:600px)');

  const { addressOptions } = useAddressOptions();

  const [loading, setLoading] = useState(false);
  const [showAccountDataForm, setShowAccountDataForm] = useState(false);
  const [customTip, setCustomTip] = useState<number | undefined>(0);
  const [tipToggle, setTipToggle] = useState<number | 'custom'>(0);

  const { paymentProvider, isHunkPay, doSquarePayment, doHunkPayPayment } = usePayment();

  const styles = useStyles({ isHunkPay });

  const tip = useMemo(() => {
    if (tipToggle === 'custom') {
      return customTip ? parseFloat(customTip?.toFixed(2)) : 0;
    }

    return parseFloat((tipToggle * paymentAmount).toFixed(2));
  }, [tipToggle, customTip, paymentAmount]);

  const billingInformationItems = useMemo(() => {
    const name = [appointment.user.first_name, appointment.user.last_name].filter(item => item).join(' ');
    const address = [appointment.user.address, appointment.user.address2].filter(item => item).join(' ');
    const state =
      addressOptions
        .find(addressOption => addressOption.countryValue === appointment.user.country)
        ?.states.find(state => state.value === appointment.user.state)?.name ?? appointment.user.state;
    const cityStatePostal = `${appointment.user.city ? `${appointment.user.city}, ` : ''}${state ? `${state} ` : ''}${
      appointment.user.postal
    }`;

    return [name, address, cityStatePostal];
  }, [appointment.user, addressOptions]);

  const accountDataDefaultValues = useMemo(
    () => ({
      firstName: appointment.user.first_name,
      lastName: appointment.user.last_name,
      address: appointment.user.address,
      address2: appointment.user.address2,
      city: appointment.user.city,
      state: appointment.user.state,
      postal: appointment.user.postal,
      country: appointment.user.country,
    }),
    [appointment.user],
  );

  const onSubmitSquareForm = useCallback(
    async (token: Square.TokenResult) => {
      if (token.status === 'OK' && token.token) {
        const { success, error } = await doSquarePayment(token.token, paymentAmount, appointment, tip);

        if (success && !error) {
          onSuccess();
        } else {
          onFailed();
        }
      }

      setLoading(false);
    },
    [appointment, paymentAmount, tip, doSquarePayment, onSuccess, onFailed],
  );

  const { renderSquarePaymentForm } = useSquareForm({
    appointment,
    paymentAmount,
    onSubmitSquareForm,
  });

  const {
    renderHunkPayForm,
    encryptedCardDetails: hunkPayEncryptedCardDetails,
    isValid: hunkPayIsValid,
    errors: hunkPayErrors,
  } = useHunkPayForm({ shouldRender: isHunkPay });

  const {
    isValid: isAccountDataFormValid,
    errors: accountDataErrors,
    renderAccountDataForm,
    getValues: getAccountDataValues,
  } = useAccountDataForm({
    defaultValues: accountDataDefaultValues,
    addressOptions,
  });

  const submitDisableReasons = useMemo(() => {
    if (!isHunkPay) {
      return '';
    }

    if (!isAccountDataFormValid) {
      const accountDataErrorsKeys = Object.keys(accountDataErrors);

      const errorsList = accountDataErrorsKeys
        .filter(key => accountDataErrorLabels.some(label => label.field === key))
        .map(key => accountDataErrorLabels.find(label => label.field === key))
        .sort((a, b) => (a?.index ?? 0) - (b?.index ?? 0))
        .map(label => label?.label ?? '');

      return (
        <Typography variant="body2">
          Please review your billing address details
          {!!errorsList.length && (
            <ul className={styles.submitDisableReasonsList}>
              {errorsList.map(error => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          )}
        </Typography>
      );
    }

    if (!hunkPayIsValid) {
      // @ts-ignore
      const hunkPayErrorsKeys = Object.keys(hunkPayErrors).filter(key => !!hunkPayErrors[key]);

      const errorsList = hunkPayErrorsKeys
        .filter(key => hunkPayErrorLabels.some(label => label.field === key))
        .map(key => hunkPayErrorLabels.find(label => label.field === key))
        .sort((a, b) => (a?.index ?? 0) - (b?.index ?? 0))
        .map(label => label?.label ?? '');

      return (
        <Typography variant="body2">
          Please review card details
          {!!errorsList.length && (
            <ul className={styles.submitDisableReasonsList}>
              {errorsList.map(error => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          )}
        </Typography>
      );
    }

    return '';
  }, [
    isHunkPay,
    isAccountDataFormValid,
    hunkPayIsValid,
    accountDataErrors.firstName,
    accountDataErrors.lastName,
    accountDataErrors.address,
    accountDataErrors.city,
    accountDataErrors.state,
    accountDataErrors.postal,
    accountDataErrors.country,
    hunkPayErrors.cardNumber,
    hunkPayErrors.expirationDate,
    hunkPayErrors.cvv,
    styles.submitDisableReasonsList,
  ]);

  const handleChangeTip = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>, value: number | 'custom' | null) => {
      if (!loading) {
        e.currentTarget.blur();
        setTipToggle(value ?? 0);

        if (value === 'custom') {
          setCustomTip(0);
        }
      }
    },
    [loading],
  );

  const handleCustomTip = useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (e.target) {
      const cTip = e.target.value.match(/(\d+(\.\d{0,2})?|\.?\d{0,2})/g);
      setCustomTip(!!cTip && !!cTip[2] ? parseFloat(cTip[2]) : undefined);
    }
  }, []);

  const onSubmitHunkPayForm = useCallback(async () => {
    setLoading(true);

    const accountData = getAccountDataValues();
    const { houseNumber, street } = parseAddress(accountData.address);

    const { success, error } = await doHunkPayPayment(
      appointment,
      hunkPayEncryptedCardDetails,
      paymentAmount,
      {
        first_name: accountData.firstName,
        last_name: accountData.lastName,
        phone: appointment.user.phone || ' ',
        email: appointment.user.email ?? '',
        address: {
          house_number: houseNumber || ' ',
          street: street || ' ',
          city: accountData.city,
          state: accountData.state,
          postal: accountData.postal,
          country: accountData.country,
        },
      },
      tip,
    );

    if (success && !error) {
      onSuccess();
    } else {
      onFailed();
    }

    setLoading(false);
  }, [
    appointment,
    paymentAmount,
    tip,
    hunkPayEncryptedCardDetails,
    doHunkPayPayment,
    onSuccess,
    onFailed,
    getAccountDataValues,
  ]);

  const onCollectPayment = useCallback(async () => {
    if (paymentProvider === PaymentProviderEnum.Square) {
      const btn = document.getElementById('square-submit-button');

      if (btn) {
        setLoading(true);
        btn.click();
      }
    } else {
      await onSubmitHunkPayForm();
    }
  }, [paymentProvider, onSubmitHunkPayForm]);

  return (
    <div className={styles.root}>
      {isHunkPay ? (
        <div>
          <Typography variant="h3" align="center" className={styles.typographyH3}>
            Payment details
          </Typography>
          <Typography variant="body1" align="center" className={styles.billingDetailsInfo}>
            Please enter your billing details as is registered on your credit card
          </Typography>
        </div>
      ) : (
        <Typography variant="h3" align="center" className={styles.typographyH3}>
          Balance due: ${paymentAmount.toFixed(2)}
        </Typography>
      )}

      {isHunkPay && (
        <div className={styles.accountDataFormContainer}>
          <Collapse in={!showAccountDataForm}>
            <Typography variant="h4" className={styles.billingInformationTitle}>
              Billing information:
            </Typography>
            <BillingInformationCard
              infoItems={billingInformationItems}
              action={{ title: 'Change', onClick: () => setShowAccountDataForm(true) }}
              hasError={!isAccountDataFormValid}
            />
          </Collapse>
          <Collapse in={showAccountDataForm}>{renderAccountDataForm()}</Collapse>
        </div>
      )}

      <div className={styles.paymentFormContainer}>
        {paymentProvider === PaymentProviderEnum.Square ? <>{renderSquarePaymentForm()}</> : <>{renderHunkPayForm()}</>}
      </div>

      {tipEnabled && (
        <div className={styles.tipContainer}>
          <div className={styles.tipContent}>
            <ToggleButtonGroup
              orientation={!isVertical ? 'horizontal' : 'vertical'}
              exclusive
              value={tipToggle}
              onChange={handleChangeTip}
              size={!isVertical ? 'large' : 'medium'}
              className={styles.tipButtonGroup}
            >
              {tipOptions.map(tipOption => (
                <ToggleButton key={tipOption.value} value={tipOption.value} className={styles.tipButton}>
                  {tipOption.label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            {tipToggle === 'custom' && (
              <div className={styles.customTip}>
                <Input value={customTip} onChange={handleCustomTip} size="medium" mask="dollars" />
              </div>
            )}
          </div>
        </div>
      )}

      {isHunkPay && (
        <>
          {tipEnabled && (
            <div className={styles.totalListContainer}>
              <ListStyled className={styles.totalList}>
                <ListItem title="Subtotal" description={paymentAmount.toFixed(2)} />
                <ListItem title="Tips" description={tip.toFixed(2)} />
              </ListStyled>
            </div>
          )}
          <Divider className={styles.divider} />
          <Typography variant="h3" className={styles.typographyH3}>
            <span style={{ color: colors.grey160 }}>Total due:</span> ${(paymentAmount + tip).toFixed(2)}
          </Typography>
        </>
      )}

      <div className={styles.makePaymentButtonContainer}>
        <div className={styles.makePaymentButtonWrapper}>
          <Tooltip
            title={submitDisableReasons}
            arrow
            placement="top"
            enterTouchDelay={0}
            disableFocusListener={!submitDisableReasons}
            disableHoverListener={!submitDisableReasons}
            disableTouchListener={!submitDisableReasons}
          >
            <div>
              <Button
                buttonType={isHunkPay ? 'twoTone' : 'filled'}
                size="large"
                fullWidth
                disabled={isHunkPay && (!hunkPayIsValid || !isAccountDataFormValid)}
                isLoading={loading}
                onClick={onCollectPayment}
              >
                {isHunkPay ? 'Make payment' : `Pay ${(paymentAmount + tip).toFixed(2)}`}
              </Button>
            </div>
          </Tooltip>
        </div>
      </div>

      <Typography variant="subtitle2" className={styles.paymentBy}>
        Payment by {paymentProvider === PaymentProviderEnum.Square ? 'Square' : 'HunkPay'}
      </Typography>
    </div>
  );
}
