import React, { useEffect, useState } from 'react';
import { TableCell, TableRow } from '@material-ui/core';

import { AppointmentManagementPanel } from '../appointmentManagement/appointmentManagementPanel/appointmentManagementPanel';
import { FinishedAppointmentManagementComponent } from '../appointmentManagement/finishedAppointmentManagementPanel/FinishedAppointmentManagementComponent';
import { MapContainer } from 'ui/map/MapContainer';
import { AppointmentPayment } from 'app/appointmentDetails/appointmentPayment/AppointmentPayment';
import { Place } from 'api/types/address';
import { usePayment } from 'hooks/usePayment/usePayment';

import { useStyles } from './AppointmentDetails.styles';
import { AppointmentDetailsProps } from './AppointmentDetails.types';
import { AppointmentLocation } from './appointmentLocation/AppointmentLocation';
import { AppointmentStatus } from './appointmentStatus/AppointmentStatus';
import { AppointmentOwner } from './appointmentOwner/AppointmentOwner';

export const AppointmentDetails = ({
  appointment,
  isSingleColumn,
  isFinished,
  showManageButtons,
}: AppointmentDetailsProps) => {
  const styles = useStyles();

  const { isHunkPay, isSquare } = usePayment();

  const [canUserPay, setCanUserPay] = useState(false);
  const [origin, setOrigin] = useState<Place>();

  useEffect(() => {
    setCanUserPay(
      (isHunkPay || isSquare) && appointment.balanceDue > 0 && (isHunkPay ? true : !!appointment.squareLocationId),
    );
    setOrigin(appointment.origin);
  }, [isHunkPay, isSquare, appointment]);

  return (
    <>
      <TableRow className={styles.detailsRow} data-testid="appointment-details">
        {!isSingleColumn ? (
          <>
            <TableCell colSpan={2}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              {origin && <MapContainer {...appointment.origin} />}
            </TableCell>
            <TableCell colSpan={isFinished ? 2 : 1}>
              <AppointmentLocation appointment={appointment} />
            </TableCell>
            <TableCell colSpan={isFinished ? 3 : 2}>
              <AppointmentStatus appointment={appointment} showConfirmButton={showManageButtons} />
              <AppointmentOwner owner={appointment.user} showUpdateButton={showManageButtons} />
              {canUserPay && (isFinished || showManageButtons) && <AppointmentPayment appointment={appointment} />}
            </TableCell>
          </>
        ) : (
          <TableCell className={styles.singleColumn} colSpan={6} align="center">
            <AppointmentLocation appointment={appointment} />
            <AppointmentStatus appointment={appointment} showConfirmButton={showManageButtons} />
            <AppointmentOwner owner={appointment.user} showUpdateButton={showManageButtons} />
            {canUserPay && (isFinished || showManageButtons) && <AppointmentPayment appointment={appointment} />}
          </TableCell>
        )}
      </TableRow>
      {showManageButtons && (
        <TableRow className={styles.detailsRow}>
          <TableCell className={styles.detailsButtonsRow} colSpan={7}>
            <AppointmentManagementPanel appointment={appointment} />
          </TableCell>
        </TableRow>
      )}
      {isFinished && (
        <TableRow className={styles.detailsRow}>
          <TableCell className={styles.detailsButtonsRow} colSpan={7}>
            <FinishedAppointmentManagementComponent appointment={appointment} />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
