import { makeStyles } from '@material-ui/core';

import { NoahFont } from 'assets/theme/typography';

export const useStyles = makeStyles({
  detailsPanel: {},
  detailsButtonsContainer: {
    textAlign: 'center',
  },
  actionButtonsContainer: {
    justifyContent: 'flex-end',
  },
  detailsButton: {
    textAlign: 'center',
  },
  phoneNumber: {
    textDecoration: 'underline',
  },
  pricingButton: {
    textTransform: 'none',
  },
  button: {
    fontFamily: NoahFont,
  },
});
