import { useParameterizedQuery } from 'react-fetching-library';
import { useCallback, useMemo } from 'react';
import { format } from 'date-fns';

import { useAsyncError } from 'hooks/useAsyncError/useAsyncError';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { hunkPayPayment, squarePayment } from 'api/actions/payment/PaymentActions';
import {
  HunkPayPaymentRequest,
  HunkPayPaymentResponse,
  PaymentRequest,
  PaymentResponse,
} from 'api/actions/payment/PaymentActions.types';
import { Appointment } from 'context/appointments/appointmentsContext/AppointmentsContext.types';
import { useLocationState } from 'hooks/useLocation/useLocaiton';
import { PaymentVendorEnum } from 'api/actions/locations/LocationsActions.types';
import { useUpdateAppointment } from 'hooks/useUpdateAppointment/useUpdateAppointment';

export enum PaymentProviderEnum {
  Square,
  HunkPay,
}

export function usePayment() {
  const throwError = useAsyncError();
  const { updateAppointment } = useUpdateAppointment();

  const { query: paymentQuery } = useParameterizedQuery<PaymentResponse>(squarePayment);
  const { query: hunkPaymentQuery } = useParameterizedQuery<HunkPayPaymentResponse>(hunkPayPayment);

  const { accountId, locationId, token } = useAuthState();
  const { location } = useLocationState();

  const paymentProvider = useMemo(() => {
    if (!location) {
      return null;
    }

    if (location.paymentVendor.id === PaymentVendorEnum.HunkPay) {
      return PaymentProviderEnum.HunkPay;
    } else if (location.paymentVendor.id === PaymentVendorEnum.Square) {
      return PaymentProviderEnum.Square;
    } else {
      const isSquareValid = location.integrations.square.active && location.integrations.square.valid;
      const isHunkPayValid = location.integrations.hunkpay.valid;

      if (isSquareValid && isHunkPayValid) {
        return PaymentProviderEnum.HunkPay;
      } else if (isHunkPayValid) {
        return PaymentProviderEnum.HunkPay;
      } else if (isSquareValid) {
        return PaymentProviderEnum.Square;
      } else {
        return null;
      }
    }
  }, [location]);

  const isHunkPay = useMemo(() => paymentProvider === PaymentProviderEnum.HunkPay, [paymentProvider]);

  const isSquare = useMemo(() => paymentProvider === PaymentProviderEnum.Square, [paymentProvider]);

  const doSquarePayment = useCallback(
    async (nonce: string, payment: number, appointment: Appointment, tip?: number) => {
      if (accountId && locationId && token) {
        const request: PaymentRequest = {
          path: { account_id: accountId, location_id: locationId, appointment_id: appointment.id },
          query: { token: token },
          payload: {
            amount: parseFloat(payment.toFixed(2)),
            tip: tip,
            nonce: nonce,
            square_location_id: appointment.squareLocationId,
          },
        };

        const { error, payload } = await paymentQuery(request);

        if (!error && payload?.payments[0]) {
          if (tip && tip > 0) {
            await updateAppointment(
              appointment.id,
              {
                account: { id: accountId },
                category: { id: appointment.category.id },
                start_date: format(appointment.startDate, 'yyyy-MM-dd HH:mm:ss'),
                end_date: format(appointment.endDate, 'yyyy-MM-dd HH:mm:ss'),
                origin: { id: appointment.origin.id },
                type: appointment.type,
                tip_amount: parseFloat((appointment.tipAmount + tip).toFixed(2)),
              },
              true,
            );
          }

          return { success: true, error: '' };
        } else if (error && payload?.messages[0]?.subject) {
          return { success: false, error: payload.messages[payload.messages.length - 1].subject };
        } else {
          throwError(`Error on: PaymentRequest, ${payload?.meta.status.description}`);
        }
      }

      return { success: false, error: 'Error on: PaymentRequest' };
    },
    [accountId, locationId, token, paymentQuery, updateAppointment, throwError],
  );

  const doHunkPayPayment = useCallback(
    async (
      appointment: Appointment,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      hunkPayFormState: any,
      amount: number,
      accountData: HunkPayPaymentRequest['payload']['account'],
      tip?: number,
    ) => {
      if (locationId && token && accountId) {
        const request: HunkPayPaymentRequest = {
          path: { location_id: locationId, appointment_id: appointment.id, account_id: accountId },
          query: { token: token },
          payload: {
            type: 'Web-Customer-Card',
            amount: parseFloat((amount + (tip ?? 0)).toFixed(2)) * 100,
            account: accountData,
            redirect: window.location.href,
            form_state: hunkPayFormState,
          },
        };

        const { error, payload } = await hunkPaymentQuery(request);

        if (!error && payload?.payments[0]) {
          if (tip && tip > 0) {
            await updateAppointment(
              appointment.id,
              {
                account: { id: accountId },
                category: { id: appointment.category.id },
                start_date: format(appointment.startDate, 'yyyy-MM-dd HH:mm:ss'),
                end_date: format(appointment.endDate, 'yyyy-MM-dd HH:mm:ss'),
                origin: { id: appointment.origin.id },
                type: appointment.type,
                tip_amount: parseFloat((appointment.tipAmount + tip).toFixed(2)),
              },
              true,
            );
          }

          return { success: true, error: '' };
        } else if (error && payload?.meta.errors && payload?.meta.errors[0]) {
          return { success: false, error: payload?.meta.errors[0].message };
        } else {
          throwError(`Error on: PaymentRequest, ${payload?.meta.status.description}`);
        }
      }

      return { success: false, error: 'Error on: PaymentRequest' };
    },
    [locationId, token, accountId, hunkPaymentQuery, updateAppointment, throwError],
  );

  return { paymentProvider, isHunkPay, isSquare, doSquarePayment, doHunkPayPayment };
}
