import { createContext } from 'react';

import { AddressOptionsStateType } from './AddressOptionsContext.types';

export const addressOptionsStateDefault: AddressOptionsStateType = {
  addressOptions: [],
  isLoading: true,
};

export const AddressOptionsContext = createContext<AddressOptionsStateType | undefined>(undefined);
