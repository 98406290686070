import { colors } from '@chhjpackages/components';
import { Theme, makeStyles } from '@material-ui/core';

type Props = {
  hasError?: boolean;
};

export const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    border: ({ hasError }) => `1px solid ${hasError ? colors.functionals.alert : colors.grey30}`,
    borderRadius: 4,
    padding: 8,
    backgroundColor: ({ hasError }) => (hasError ? colors.functionals.alertLight : colors.white),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 16,
  },
  infoItem: {
    color: ({ hasError }) => (hasError ? colors.functionals.alert : colors.grey140),
  },
}));
