import { useParameterizedQuery } from 'react-fetching-library';
import { useCallback } from 'react';

import { updateAppointment as updateAppointmentRequest } from 'api/actions/appointments/AppointmentsActions';
import {
  AppointmentsResponse,
  UpdateAppointmentPayload,
  UpdateAppointmentRequest,
} from 'api/actions/appointments/AppointmentsActions.types';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { useAsyncError } from 'hooks/useAsyncError/useAsyncError';

export const useUpdateAppointment = () => {
  const throwError = useAsyncError();

  const { query: updateAppointmentQuery } = useParameterizedQuery<AppointmentsResponse>(updateAppointmentRequest);

  const { accountId, locationId, token } = useAuthState();

  const updateAppointment = useCallback(
    async (appointmentId: number, payload: UpdateAppointmentPayload, ignoreError = false) => {
      if (accountId && locationId && token) {
        const updateAppointmentRequest: UpdateAppointmentRequest = {
          path: { account_id: accountId, appointment_id: appointmentId, location_id: locationId },
          query: { token: token },
          payload: payload as UpdateAppointmentPayload,
        };

        const { error, payload: response } = await updateAppointmentQuery(updateAppointmentRequest);

        if (!error && response?.appointments[0]) {
          return response?.appointments[0];
        } else {
          if (!ignoreError) {
            throwError(`Error on: UpdateAppointment, ${response?.meta.status.description}`);
          }
        }
      }
    },
    [accountId, locationId, token, updateAppointmentQuery, throwError],
  );

  return { updateAppointment };
};
