import React, { useReducer, useEffect } from 'react';

import { authStorage } from '../authStorage/AuthStorage';
import { AuthDispatchContext, AuthStateContext } from 'context/auth/authContext/AuthContext';
import { authReducer } from 'context/auth/authReducer/authReducer';

import { AuthContextControllerProps } from './AuthContextController.types';

export const AuthContextController = ({ children }: AuthContextControllerProps) => {
  const [state, dispatch] = useReducer(authReducer, {
    isAuthorized: false,
    isAuthorizing: true,
    user: undefined,
    token: null,
    accountId: null,
    locationId: null,
    expires: authStorage.expires,
  });

  useEffect(() => {
    authStorage.token = state.token;
    authStorage.expires = state.expires;
    authStorage.accountId = state.accountId;
    authStorage.locationId = state.locationId;
  }, [state.token, state.expires, state.accountId, state.locationId]);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};
