import { Appointment } from 'context/appointments/appointmentsContext/AppointmentsContext.types';

export type PaymentFormProps = {
  appointment: Appointment;
  paymentAmount: number;
  tipEnabled: boolean;
  onSuccess: () => void;
  onFailed: () => void;
};

export enum PaymentProviderEnum {
  Square,
  HunkPay,
}
