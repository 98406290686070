import { ApiRequestPath, ApiRequestQuery, ApiResponseBase } from 'api/types/api';

export type LocationsRequest = {
  path: Pick<ApiRequestPath, 'location_id' | 'account_id'>;
  query: Pick<ApiRequestQuery, 'token'>;
};

export type LocationResponseData = {
  payment_vendor: {
    id: number;
    name: string;
  };
  integrations: {
    hunkpay: {
      valid: boolean;
    };
    square: {
      active: boolean;
      valid: true;
    };
  };
};

export type LocationResponse = {
  locations: LocationResponseData[];
} & ApiResponseBase;

export enum PaymentVendorEnum {
  Automatic = 1,
  HunkPay = 2,
  Square = 3,
}
