import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  alert: {
    width: '600px',
    maxWidth: '80%',
    '& .MuiAlert-message': {
      flex: '1',
      textAlign: 'center',
    },
    '& .MuiAlert-action': {
      paddingLeft: '0',
    },
  },
}));
