import { makeStyles } from '@material-ui/core';

import { colors } from 'assets/theme/palette';

export const useStyles = makeStyles(theme => ({
  dashed: {
    margin: '0 0 30px',
    borderTop: `5px dashed ${colors.grey20}`,
    width: '100%',
  },
}));
