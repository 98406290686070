import { List, Theme, createStyles, makeStyles, withStyles } from '@material-ui/core';
import { colors } from '@chhjpackages/components';

type Props = {
  isHunkPay: boolean;
};

export const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    width: '100%',
  },
  billingInformationTitle: {
    fontSize: 14,
    lineHeight: '16.8px',
    color: colors.grey140,
  },
  billingDetailsInfo: {
    marginTop: 4,
  },
  accountDataFormContainer: {
    marginTop: 16,
  },
  paymentFormContainer: {
    marginTop: ({ isHunkPay }) => (isHunkPay ? 16 : 24),
  },
  tipContainer: {
    display: 'flex',
    marginTop: ({ isHunkPay }) => (isHunkPay ? 16 : 8),
    alignItems: ({ isHunkPay }) => (isHunkPay ? 'left' : 'center'),
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },
    flexDirection: 'column',
  },
  tipContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tipButtonGroup: {
    width: '100%',

    [theme.breakpoints.down('xs')]: {
      width: 'initial',
    },
  },
  tipButton: {
    flex: 1,

    [theme.breakpoints.down('xs')]: {
      flex: 'initial',
    },
  },
  divider: {
    margin: '16px 0px',
  },
  customTip: {
    marginTop: '16px',
    maxWidth: '150px',
    alignSelf: 'center',
  },
  totalListContainer: {
    marginTop: 16,
  },
  totalList: {
    border: `1px solid ${colors.grey10}`,
    borderRadius: 4,
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  typographyH3: {
    fontSize: 32,
  },
  paymentBy: {
    marginTop: 16,
    fontSize: 12,
    textAlign: 'center',
    color: colors.basic.black,
  },
  makePaymentButtonContainer: {
    marginTop: ({ isHunkPay }) => (isHunkPay ? 16 : 32),
    display: 'flex',
    justifyContent: 'center',
  },
  makePaymentButtonWrapper: {
    width: ({ isHunkPay }) => (isHunkPay ? 181 : '100%'),
    marginLeft: ({ isHunkPay }) => (isHunkPay ? 7 : 0),
  },
  submitDisableReasonsList: {
    margin: 0,
    paddingLeft: 20,
  },
}));

export const ListStyled = withStyles(() =>
  createStyles({
    root: {
      padding: 0,
      '& .MuiListItem-root:nth-child(even)': {
        backgroundColor: colors.grey10,
      },
    },
  }),
)(List);
