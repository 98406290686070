import { useContext } from 'react';

import { HunkPayStateContext } from 'context/hunkPay/hunkPayContext/HunkPayContext';
import { HunkPayStateType } from 'context/hunkPay/hunkPayContext/HunkPayContext.types';

export const useHunkPayState = (): HunkPayStateType => {
  const context = useContext(HunkPayStateContext);

  if (context === undefined) {
    throw new Error('useHunPayState must be used within a HunkPayContextController');
  }

  return context;
};
