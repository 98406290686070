import { makeStyles } from '@material-ui/core';

import { colors } from 'assets/theme/palette';

export const useStyles = (size: 'small' | 'medium' | 'large') => {
  const loaderSize = size === 'small' ? 20 : size === 'medium' ? 40 : 56;
  const loaderWidth = size === 'small' ? 2 : size === 'medium' ? 4 : 6;
  const style = makeStyles({
    loading: {
      height: '100%',
    },
    container: {
      width: `${loaderSize}px`,
      height: `${loaderSize}px`,
      position: 'relative',
    },
    circle: {
      animation: '$rotation 1s infinite linear',
      position: 'absolute',
      width: '100%',
      height: '100%',
      border: `${loaderWidth}px solid transparent`,
      borderRadius: '50%',
    },
    quarter: {
      border: `${loaderWidth}px solid ${colors.orange}`,
      borderRadius: `${loaderSize / 2}px 0 0  0`,
      width: `${loaderSize / 2}px`,
      height: `${loaderSize / 2}px`,
      borderRightWidth: '0',
      borderBottomWidth: '0',
      position: 'relative',
      top: `-${loaderWidth}px`,
      left: `-${loaderWidth}px`,
      boxSizing: 'content-box',
    },
    '@keyframes rotation': {
      '0%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(359deg)' },
    },
  });
  return style();
};
