import { makeStyles } from '@material-ui/core';

import { colors } from 'assets/theme/palette';

export const useStyles = makeStyles({
  root: {
    margin: '40px 0',
    textAlign: 'center',
  },
  pricePoint: {},
  container: {},
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  notAvailable: {
    color: colors.grey,
    fontWeight: 'normal',
  },
});
