import React from 'react';
import { Typography } from '@material-ui/core';

import { useStyles } from './CallUsComponent.styles';

export const CallUsComponent = ({ message }: { message: string }) => {
  const styles = useStyles();
  return (
    <div>
      <Typography variant="body1">{message} Call us.</Typography>
      <Typography variant="body1" color="secondary" className={styles.phoneNumber}>
        833-626-1326
      </Typography>
    </div>
  );
};
