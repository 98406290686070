import React from 'react';
import { Typography } from '@material-ui/core';
import { useMediaQuery, useTheme } from '@material-ui/core';

import AngryFace from 'assets/images/angry.png';
import NeutralFace from 'assets/images/neutral.png';
import HappyFace from 'assets/images/satisfied.png';
import { useDialogDispatch } from 'hooks/useDialog/useDialog';
import { ReviewAppointmentNeutral } from 'app/appointmentManagement/finishedAppointmentManagementPanel/reviewAppointmentDialog/reviewAppointmentNeutral/ReviewAppointmentNeutral';
import { ReviewAppointmentAngry } from 'app/appointmentManagement/finishedAppointmentManagementPanel/reviewAppointmentDialog/reviewAppointmentAngry/ReviewAppointmentAngry';
import { ReviewAppointmentSocial } from 'app/appointmentManagement/finishedAppointmentManagementPanel/reviewAppointmentDialog/reviewAppointmentSocial/ReviewAppointmentSocial';
import { useReviewAppointment } from 'hooks/useReviewAppointment/useReviewAppointment';

import { ReviewAppointmentFaceProps } from './ReviewAppointmentFace.types';
import { useStyles } from './ReviewAppointmentFace.styles';

export function ReviewAppointmentFace({ appointment }: ReviewAppointmentFaceProps) {
  const styles = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const setDialog = useDialogDispatch();
  const reviewAppointment = useReviewAppointment();
  const [value, setValue] = React.useState<number>(-1);

  const handleReviewClick = async () => {
    let faceType = null;
    if (value !== -1) {
      await reviewAppointment(value, '', '', appointment.id);
      if (value < 7) {
        faceType = <ReviewAppointmentAngry appointment={appointment} reviewValue={value} />;
      } else if (value > 6 && value < 9) {
        faceType = <ReviewAppointmentNeutral appointment={appointment} reviewValue={value} />;
      } else {
        faceType = <ReviewAppointmentSocial appointment={appointment} reviewValue={value} />;
      }

      setDialog({
        open: true,
        dialogContent: faceType,
      });
    }
  };

  return (
    <div className={styles.faceContainer}>
      {isMobile ? (
        <>
          <div>
            <div className={styles.numPoint}>
              <div
                onClick={() => setValue(0)}
                style={{ background: value === 0 ? '#026937' : '#FFFFFF' }}
                className={styles.numBox}
              >
                <p className={styles.singleNumber} style={{ color: value === 0 ? '#FFFFFF' : '#000000' }}>
                  0
                </p>
              </div>
              <div
                onClick={() => setValue(1)}
                style={{ background: value === 1 ? '#026937' : '#FFFFFF' }}
                className={styles.numBox}
              >
                <p className={styles.singleNumber} style={{ color: value === 1 ? '#FFFFFF' : '#000000' }}>
                  1
                </p>
              </div>
              <div
                onClick={() => setValue(2)}
                style={{ background: value === 2 ? '#026937' : '#FFFFFF' }}
                className={styles.numBox}
              >
                <p className={styles.singleNumber} style={{ color: value === 2 ? '#FFFFFF' : '#000000' }}>
                  2
                </p>
              </div>
              <div
                onClick={() => setValue(3)}
                style={{ background: value === 3 ? '#026937' : '#FFFFFF' }}
                className={styles.numBox}
              >
                <p className={styles.singleNumber} style={{ color: value === 3 ? '#FFFFFF' : '#000000' }}>
                  3
                </p>
              </div>
              <div
                onClick={() => setValue(4)}
                style={{ background: value === 4 ? '#026937' : '#FFFFFF' }}
                className={styles.numBox}
              >
                <p className={styles.singleNumber} style={{ color: value === 4 ? '#FFFFFF' : '#000000' }}>
                  4
                </p>
              </div>
            </div>
            <div style={{ marginTop: '0.7rem' }} className={styles.numPoint}>
              <div
                onClick={() => setValue(5)}
                style={{ background: value === 5 ? '#026937' : '#FFFFFF' }}
                className={styles.numBox}
              >
                <p className={styles.singleNumber} style={{ color: value === 5 ? '#FFFFFF' : '#000000' }}>
                  5
                </p>
              </div>
              <div
                onClick={() => setValue(6)}
                style={{ background: value === 6 ? '#026937' : '#FFFFFF' }}
                className={styles.numBox}
              >
                <p className={styles.singleNumber} style={{ color: value === 6 ? '#FFFFFF' : '#000000' }}>
                  6
                </p>
              </div>
              <div
                onClick={() => setValue(7)}
                style={{ background: value === 7 ? '#026937' : '#FFFFFF' }}
                className={styles.numBox}
              >
                <p className={styles.singleNumber} style={{ color: value === 7 ? '#FFFFFF' : '#000000' }}>
                  7
                </p>
              </div>
              <div
                onClick={() => setValue(8)}
                style={{ background: value === 8 ? '#026937' : '#FFFFFF' }}
                className={styles.numBox}
              >
                <p className={styles.singleNumber} style={{ color: value === 8 ? '#FFFFFF' : '#000000' }}>
                  8
                </p>
              </div>
              <div
                onClick={() => setValue(9)}
                style={{ background: value === 9 ? '#026937' : '#FFFFFF' }}
                className={styles.numBox}
              >
                <p className={styles.singleNumber} style={{ color: value === 9 ? '#FFFFFF' : '#000000' }}>
                  9
                </p>
              </div>
              <div
                onClick={() => setValue(10)}
                style={{ background: value === 10 ? '#026937' : '#FFFFFF' }}
                className={styles.numBox}
              >
                <p className={styles.singleNumber} style={{ color: value === 10 ? '#FFFFFF' : '#000000' }}>
                  10
                </p>
              </div>
            </div>
          </div>
          <div className={styles.numEndText}>
            <div className={styles.notLikely}>0-NOT LIKELY</div>
            <div className={styles.veryLikely}>10-VERY LIKELY</div>
          </div>

          <div className={styles.submitDiv}>
            <button className={styles.submitButtonStyle} onClick={handleReviewClick}>
              SUBMIT
            </button>
          </div>
        </>
      ) : (
        <>
          <div className={styles.numPoint}>
            <div
              onClick={() => setValue(0)}
              style={{ background: value === 0 ? '#026937' : '#FFFFFF' }}
              className={styles.numBox}
            >
              <p className={styles.singleNumber} style={{ color: value === 0 ? '#FFFFFF' : '#000000' }}>
                0
              </p>
            </div>
            <div
              onClick={() => setValue(1)}
              style={{ background: value === 1 ? '#026937' : '#FFFFFF' }}
              className={styles.numBox}
            >
              <p className={styles.singleNumber} style={{ color: value === 1 ? '#FFFFFF' : '#000000' }}>
                1
              </p>
            </div>
            <div
              onClick={() => setValue(2)}
              style={{ background: value === 2 ? '#026937' : '#FFFFFF' }}
              className={styles.numBox}
            >
              <p className={styles.singleNumber} style={{ color: value === 2 ? '#FFFFFF' : '#000000' }}>
                2
              </p>
            </div>
            <div
              onClick={() => setValue(3)}
              style={{ background: value === 3 ? '#026937' : '#FFFFFF' }}
              className={styles.numBox}
            >
              <p className={styles.singleNumber} style={{ color: value === 3 ? '#FFFFFF' : '#000000' }}>
                3
              </p>
            </div>
            <div
              onClick={() => setValue(4)}
              style={{ background: value === 4 ? '#026937' : '#FFFFFF' }}
              className={styles.numBox}
            >
              <p className={styles.singleNumber} style={{ color: value === 4 ? '#FFFFFF' : '#000000' }}>
                4
              </p>
            </div>
            <div
              onClick={() => setValue(5)}
              style={{ background: value === 5 ? '#026937' : '#FFFFFF' }}
              className={styles.numBox}
            >
              <p className={styles.singleNumber} style={{ color: value === 5 ? '#FFFFFF' : '#000000' }}>
                5
              </p>
            </div>
            <div
              onClick={() => setValue(6)}
              style={{ background: value === 6 ? '#026937' : '#FFFFFF' }}
              className={styles.numBox}
            >
              <p className={styles.singleNumber} style={{ color: value === 6 ? '#FFFFFF' : '#000000' }}>
                6
              </p>
            </div>
            <div
              onClick={() => setValue(7)}
              style={{ background: value === 7 ? '#026937' : '#FFFFFF' }}
              className={styles.numBox}
            >
              <p className={styles.singleNumber} style={{ color: value === 7 ? '#FFFFFF' : '#000000' }}>
                7
              </p>
            </div>
            <div
              onClick={() => setValue(8)}
              style={{ background: value === 8 ? '#026937' : '#FFFFFF' }}
              className={styles.numBox}
            >
              <p className={styles.singleNumber} style={{ color: value === 8 ? '#FFFFFF' : '#000000' }}>
                8
              </p>
            </div>
            <div
              onClick={() => setValue(9)}
              style={{ background: value === 9 ? '#026937' : '#FFFFFF' }}
              className={styles.numBox}
            >
              <p className={styles.singleNumber} style={{ color: value === 9 ? '#FFFFFF' : '#000000' }}>
                9
              </p>
            </div>
            <div
              onClick={() => setValue(10)}
              style={{ background: value === 10 ? '#026937' : '#FFFFFF' }}
              className={styles.numBox}
            >
              <p className={styles.singleNumber} style={{ color: value === 10 ? '#FFFFFF' : '#000000' }}>
                10
              </p>
            </div>
          </div>
          <div className={styles.numEndText}>
            <div className={styles.notLikely}>NOT LIKELY</div>
            <div className={styles.veryLikely}>VERY LIKELY</div>
          </div>

          <div className={styles.submitDiv}>
            <button className={styles.submitButtonStyle} onClick={handleReviewClick}>
              SUBMIT
            </button>
          </div>
        </>
      )}
    </div>
  );
}
