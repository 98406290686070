import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars';
import Measure from 'react-measure';

import { LowHouseIcon } from 'assets/icons/LowHouseIcon';
import { TruckIcon } from 'assets/icons/TruckIcon';
import { useAppointmentsState, useAppointmentsDispatch } from 'hooks/useAppointments/useAppointments';
import { AppointmentsTableContainer } from 'app/appointmentsTable/AppointmentsTableContainer';
import { SET_RELOAD_APPOINTMENTS } from 'context/appointments/appointmentsContextReducer/AppointmentsContextReducer.types';
import { useMessagesDispatch } from 'hooks/useMessages/useMessages';
import { ReviewAppointmentDialog } from 'app/appointmentManagement/finishedAppointmentManagementPanel/reviewAppointmentDialog/ReviewAppointmentDialog';
import { useDialogDispatch } from 'hooks/useDialog/useDialog';
import { PaymentForm } from 'ui/paymentForm/PaymentForm';
import { useLocationState } from 'hooks/useLocation/useLocaiton';
import { usePayment } from 'hooks/usePayment/usePayment';

import { useStyles } from './Appointments.styles';
import { AppointmentsProps } from './Appointments.types';

export const Appointments = ({
  expandedId,
  beginReview,
  makePayment,
  paymentAmount,
  tipEnabled,
}: AppointmentsProps) => {
  const styles = useStyles();

  const {
    appointments,
    upcomingAppointments,
    finishedAppointments,
    isLoading: isAppointmentLoading,
  } = useAppointmentsState();
  const { isLoading: isLocationLoading } = useLocationState();

  const appointmentsDispatch = useAppointmentsDispatch();
  const setMessage = useMessagesDispatch();
  const setDialog = useDialogDispatch();

  const { isHunkPay } = usePayment();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const scrollbarsRef = useRef<any>(null);

  const [expandAppointmentId, setExpandAppointmentId] = useState<number>();

  const isLoading = useMemo(() => isAppointmentLoading || isLocationLoading, [isAppointmentLoading, isLocationLoading]);

  const handlePaymentSuccess = useCallback(() => {
    setDialog({ open: false });
    appointmentsDispatch({ type: SET_RELOAD_APPOINTMENTS });
    setMessage({ message: 'Payment successful!', type: 'success' });
  }, [setDialog, appointmentsDispatch, setMessage]);

  const handlePaymentFailed = useCallback(() => {
    setMessage({ message: 'Payment failed!', type: 'error' });
  }, [setMessage]);

  useEffect(() => {
    appointmentsDispatch({ type: SET_RELOAD_APPOINTMENTS });
  }, [appointmentsDispatch]);

  useEffect(() => {
    setExpandAppointmentId(expandedId);
  }, [expandedId]);

  useEffect(() => {
    if (
      !!expandAppointmentId &&
      appointments.length !== 0 &&
      !appointments.find(appointment => appointment.id === expandAppointmentId)
    ) {
      setMessage({
        message: `Could not find appointment number #${expandAppointmentId}`,
        type: 'warning',
      });
    } else if (
      !!expandAppointmentId &&
      appointments.length !== 0 &&
      appointments.find(appointment => appointment.id === expandAppointmentId)
    ) {
      setTimeout(() => {
        const element = document.getElementById(`appointment-${expandAppointmentId}`);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
          setExpandAppointmentId(undefined);
        }
      }, 0);
    }
  }, [appointments, expandAppointmentId, setMessage]);

  useEffect(() => {
    if (
      beginReview &&
      !!expandAppointmentId &&
      appointments.length !== 0 &&
      appointments.find(appointment => appointment.id === expandAppointmentId)
    ) {
      setDialog({
        open: true,
        dialogContent: <ReviewAppointmentDialog appointment={appointments.filter(item => item.id === expandedId)[0]} />,
      });
    }
  }, [beginReview, appointments, expandAppointmentId, setDialog, expandedId]);

  useEffect(() => {
    let amountToPay = appointments.filter(item => item.id === expandedId)[0]?.balanceDue || 0;
    if (paymentAmount && paymentAmount > 0) {
      amountToPay = paymentAmount;
    }
    const appointment = appointments.find(appointment => appointment.id === expandAppointmentId);
    if (
      !isLoading &&
      makePayment &&
      !!expandAppointmentId &&
      appointments.length !== 0 &&
      appointment &&
      appointment.type === 'JOB' &&
      !['Canceled', 'Lost'].includes(appointment.status.name) &&
      tipEnabled !== undefined
    ) {
      setDialog({
        open: true,
        dialogContent: (
          <Scrollbars
            autoHeight
            autoHeightMin="100%"
            autoHeightMax="100%"
            ref={scrollbarsRef}
            renderView={renderProps => (
              <div
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...renderProps}
                style={{
                  ...renderProps.style,
                  overflowX: 'hidden',
                }}
              />
            )}
            renderTrackHorizontal={() => <div style={{ display: 'none' }} />}
            renderThumbHorizontal={() => <div style={{ display: 'none' }} />}
            autoHideTimeout={800}
            autoHideDuration={200}
          >
            <Measure
              bounds
              onResize={() => {
                scrollbarsRef.current && scrollbarsRef.current.forceUpdate();
              }}
            >
              {({ measureRef }) => (
                <div ref={measureRef} style={{ maxWidth: isHunkPay ? 700 : 446, paddingRight: 0.5 }}>
                  <div style={{ maxWidth: '100%', width: 'calc(100vw - 124px)' }}>
                    <PaymentForm
                      appointment={appointments.filter(item => item.id === expandedId)[0]}
                      paymentAmount={amountToPay}
                      tipEnabled={tipEnabled}
                      onSuccess={handlePaymentSuccess}
                      onFailed={handlePaymentFailed}
                    />
                  </div>
                </div>
              )}
            </Measure>
          </Scrollbars>
        ),
      });
    }
  }, [
    isLoading,
    isHunkPay,
    makePayment,
    paymentAmount,
    tipEnabled,
    appointments,
    expandAppointmentId,
    expandedId,
    handlePaymentSuccess,
    handlePaymentFailed,
    setDialog,
  ]);

  return (
    <div className={styles.root} data-testid="appointments">
      <div>
        <Grid container className={styles.tableContainer}>
          <Grid item className={styles.tableTitle}>
            <TruckIcon className={styles.tableIcon} />
            <Typography variant="h4">Upcoming appointments</Typography>
          </Grid>
          <Grid item xs={12}>
            <AppointmentsTableContainer
              appointments={upcomingAppointments}
              isLoading={isLoading}
              expandedItemId={upcomingAppointments.find(appointment => appointment.id === expandAppointmentId)?.id}
            />
          </Grid>
        </Grid>
      </div>
      <div className={styles.finished}>
        <Grid container className={styles.tableContainer}>
          <Grid item className={styles.tableTitle}>
            <LowHouseIcon className={styles.tableIcon} />
            <Typography variant="h4">Finished appointments</Typography>
          </Grid>
          <Grid item xs={12}>
            <AppointmentsTableContainer
              appointments={finishedAppointments}
              isLoading={isLoading}
              isFinished
              expandedItemId={finishedAppointments.find(appointment => appointment.id === expandAppointmentId)?.id}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
