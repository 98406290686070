import { colors } from '@chhjpackages/components';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  title: {
    color: colors.black,
    fontWeight: 'bold',
  },
  description: {
    color: colors.grey180,
  },
}));
