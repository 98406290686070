import { Grid, Typography, Hidden } from '@material-ui/core';
import React from 'react';
import LocalShipping from '@material-ui/icons/LocalShipping';

import { Button } from 'ui/button/Button';
import { useDialogDispatch } from 'hooks/useDialog/useDialog';
import { useRequestEta } from 'hooks/useRequestEta/useRequestEta';

import { CheckEtaSentDialog } from './checkEtaSentDialog/CheckEtaSentDialog';
import { RequestEtaComponentProps } from './CheckEtaDialog.types';
import { useStyles } from './CheckEtaDialog.styles';

export function CheckEtaDialog({ appointment }: RequestEtaComponentProps) {
  const styles = useStyles();
  const setDialog = useDialogDispatch();
  const requestEta = useRequestEta();

  const closeDialog = () => {
    setDialog({ open: false });
  };

  const sendEta = async () => {
    await requestEta(appointment.id);
    setDialog({ open: true, dialogContent: <CheckEtaSentDialog /> });
  };

  return (
    <Grid container direction="row" alignItems="center" justify="center" spacing={4} className={styles.dialogContainer}>
      <Grid item>
        <Typography variant="h2">
          Check the status <span className={styles.subtitle}>of your local HUNKS</span>
        </Typography>
      </Grid>

      <Hidden smDown>
        <Grid item>
          <LocalShipping className={styles.checkIcon} />
        </Grid>
      </Hidden>

      <Grid item>
        <Typography variant="body1" color="primary" className={styles.dialogDescription}>
          We can send a message to your local team to reach out to you with an ETA. Would you like to continue?
        </Typography>
      </Grid>

      <Grid item container spacing={1} alignItems="center" className={styles.etaButtonsContainer}>
        <Grid item>
          <Button buttonType="outlined" onClick={() => sendEta()} className={styles.etaButton}>
            Yes
          </Button>
        </Grid>
        <Grid item>
          <Button buttonType="outlined" onClick={() => closeDialog()} className={styles.etaButton}>
            No
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
