import { createContext } from 'react';

import { HunkPayStateType } from './HunkPayContext.types';

export const hunkPayStateDefault: HunkPayStateType = {
  paymentMethods: [],
  isLoading: true,
};

export const HunkPayStateContext = createContext<HunkPayStateType | undefined>(undefined);
