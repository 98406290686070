import { SET_AUTHORIZED, SET_TOKENS, SET_UNAUTHORIZED, START_AUTHORIZING, LOGOUT } from '../authReducer/authReducer';
import { AuthAction } from '../authReducer/authReducer.types';
import { UserAccount } from 'api/types/account';

export const setAuthorized: (user: UserAccount) => AuthAction = user => ({
  type: SET_AUTHORIZED,
  user,
});

export const setUnauthorized: () => AuthAction = () => ({
  type: SET_UNAUTHORIZED,
});

export const setTokens: ({
  token,
  accountId,
  locationId,
}: {
  token: string;
  accountId: number;
  locationId: number;
}) => AuthAction = ({ token, accountId, locationId }) => ({
  type: SET_TOKENS,
  token,
  accountId,
  locationId,
});

export const logout: () => AuthAction = () => ({
  type: LOGOUT,
});

export const startAuthorizing: () => AuthAction = () => ({
  type: START_AUTHORIZING,
});
