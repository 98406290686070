import { Grid, Typography, MenuItem, FormControl, FormHelperText } from '@material-ui/core';
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextArea } from '@chhjpackages/components';

import { ReviewFormData } from '../ReviewAppointmentDialog.types';
import { Select } from 'ui/select/Select';
import { Button } from 'ui/button/Button';
import { useReviewAppointment } from 'hooks/useReviewAppointment/useReviewAppointment';
import { ReviewAppointmentThankYou } from '../reviewAppointmentThankYou/ReviewAppointmentThankYou';
import { useDialogDispatch } from 'hooks/useDialog/useDialog';

import { ReviewAppointmentAngryProps } from './ReviewAppointmentAngry.types';
import { useStyles } from './ReviewAppointmentAngry.styles';

export function ReviewAppointmentAngry({ appointment, reviewValue }: ReviewAppointmentAngryProps) {
  const styles = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, register, control, errors } = useForm<ReviewFormData>();
  const reviewAppointment = useReviewAppointment();
  const setDialog = useDialogDispatch();

  const submit = async (values: ReviewFormData) => {
    setIsLoading(true);
    await reviewAppointment(reviewValue, values.message, digestReason(values.subject), appointment.id);
    setDialog({
      open: true,
      dialogContent: <ReviewAppointmentThankYou appointment={appointment} reviewType={'un-satisfied'} />,
    });
  };

  const digestReason = (reason: string) => {
    switch (reason) {
      case 'Damage To My Item(s)':
        return 'Property Damage';
      case 'Damage(s) To My Property':
        return 'Property Damage';
      case 'I Am Missing Item(s)':
        return 'Missing Property';
      case 'Incorrect Amount Charged To My Card':
        return 'Incorrect Amount Charged';
      case '-1':
        return '';
      default:
        return 'Other';
    }
  };

  const problemReasons = [
    'Job Is Incomplete',
    'Damage To My Item(s)',
    'Damage(s) To My Property',
    'I Am Missing Item(s)',
    'Incorrect Amount Charged To My Card',
    'Other',
  ];

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Grid container direction="row" alignItems="center" spacing={2} className={styles.dialogContainer}>
        <Grid item xs={12}>
          <Typography variant="h3">
            We are sorry that you are not satisfied with your service.
            <br />
            <span className={styles.subtitle}>Let's make things right.</span>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={styles.feedbackInput} error={!!errors.subject?.message}>
            <Controller
              as={
                <Select fullWidth variant={'outlined'}>
                  <MenuItem value={0} disabled>
                    <span className="select-placeholder">What went wrong?</span>
                  </MenuItem>
                  {problemReasons.map((reason, index) => {
                    return (
                      <MenuItem value={reason} key={index}>
                        {reason}
                      </MenuItem>
                    );
                  })}
                </Select>
              }
              name="subject"
              control={control}
              rules={{
                validate: value => (!value ? 'Please select what went wrong' : true),
              }}
              defaultValue={0}
            />
            <div>
              <FormHelperText>{errors.subject && errors.subject.message}</FormHelperText>
            </div>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={styles.feedbackInput} error={!!errors.message?.message}>
            <TextArea
              size={'large'}
              variant={'outlined'}
              fullWidth
              placeholder={'Description'}
              rows={5}
              name="message"
              inputRef={register({
                required: 'This field is required',
                minLength: {
                  value: 5,
                  message: 'This field should have at least 5 characters',
                },
              })}
            />
            <div>
              <FormHelperText>{errors.message && errors.message.message}</FormHelperText>
            </div>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button buttonType="twoTone" type="submit" isLoading={isLoading}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
