import { HunkPayPaymentMethod } from '../hunkPayContext/HunkPayContext.types';

export const SET_HUNKPAY_PAYMENT_METHODS = 'SET_HUNKPAY_PAYMENT_METHODS';

export type SetHunkPayPaymentMethods = typeof SET_HUNKPAY_PAYMENT_METHODS;

export type SimpleAction<Type> = {
  type: Type;
};

export type Action<Type, Payload> = SimpleAction<Type> & {
  payload: Payload;
};

export type SetHunkPayPaymentMethodsAction = Action<
  SetHunkPayPaymentMethods,
  { paymentMethods: HunkPayPaymentMethod[] }
>;

export type HunkPayActions = SetHunkPayPaymentMethodsAction;
