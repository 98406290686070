import React from 'react';
import { SelectProps } from '@material-ui/core';

import { SelectStyled } from './Select.styles';

export const Select = React.forwardRef(({ ...props }: SelectProps, ref) => {
  return (
    <SelectStyled
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={ref}
      fullWidth
      displayEmpty
      color="secondary"
    >
      {props.children}
    </SelectStyled>
  );
});
