import React from 'react';
import { Grid } from '@material-ui/core';

import { useStyles } from './Loader.styles';

export const Loader = ({ size = 'large' }: { size?: 'small' | 'medium' | 'large' }) => {
  const styles = useStyles(size);

  return (
    <Grid className={styles.loading} container justify="center" alignItems="center">
      <div className={styles.container}>
        <div className={styles.circle}>
          <div className={styles.quarter} />
        </div>
      </div>
    </Grid>
  );
};
