import React from 'react';

import { Page } from 'ui/page/Page';
import './App.css';

export const App = () => {
  return (
    <main className="app">
      <Page />
    </main>
  );
};
