import React from 'react';
import { Grid } from '@material-ui/core';

import { useStyles } from './ReviewAppointmentFaces.styles';
import { ReviewAppointmentFaceProps } from './ReviewAppointmentFaces.types';
import { ReviewAppointmentFace } from './reviewAppointmentFace/ReviewAppointmentFace';
import { FaceTypes } from './reviewAppointmentFace/ReviewAppointmentFace.types';

export function ReviewAppointmentFaces({ appointment }: ReviewAppointmentFaceProps) {
  const styles = useStyles();

  return (
    <ReviewAppointmentFace appointment={appointment} />

    // <Grid container direction="row" alignItems="center" justify="center" spacing={0}>
    //   <Grid item xs={6} sm={3}>
    //     <ReviewAppointmentFace appointment={appointment} faceType={FaceTypes.angry} />
    //   </Grid>
    //   <div className={styles.faceSeparatorLeft}></div>
    //   <Grid item xs={6} sm={3}>
    //     <ReviewAppointmentFace appointment={appointment} faceType={FaceTypes.neutral} />
    //   </Grid>
    //   <div className={styles.faceSeparatorRight}></div>
    //   <Grid item xs={6} sm={3}>
    //     <ReviewAppointmentFace appointment={appointment} faceType={FaceTypes.happy} />
    //   </Grid>
    // </Grid>

    // <React.Fragment>
    //   <ReviewAppointmentFace appointment={appointment} faceText="un-satisfied" faceUrl={AngryFace}/>
    //   <div className={styles.faceSeparatorLeft}></div>
    //   <ReviewAppointmentFace appointment={appointment} faceText="neutral" faceUrl={NeutralFace}/>
    //   <div className={styles.faceSeparatorRight}></div>
    //   <ReviewAppointmentFace appointment={appointment} faceText="satisfied" faceUrl={HappyFace}/>
    // </React.Fragment>
  );
}
