import React, { useState } from 'react';
import { Grid } from '@material-ui/core';

import { DashedSeparator } from 'ui/dashedSeparator/DashedSeparator';
import { Button } from 'ui/button/Button';
import { useDialogDispatch } from 'hooks/useDialog/useDialog';
import { ReviewAppointmentAngry } from 'app/appointmentManagement/finishedAppointmentManagementPanel/reviewAppointmentDialog/reviewAppointmentAngry/ReviewAppointmentAngry';

import { FinishedAppointmentManagementComponentProps } from './FinishedAppointmentManagementComponent.types';
import { useStyles } from './FinishedAppointmentManagementComponent.styles';
import { ReviewAppointmentDialog } from './reviewAppointmentDialog/ReviewAppointmentDialog';

export function FinishedAppointmentManagementComponent({ appointment }: FinishedAppointmentManagementComponentProps) {
  const styles = useStyles();
  const setDialog = useDialogDispatch();

  const handleReview = async () => {
    setDialog({
      open: true,
      dialogContent: <ReviewAppointmentDialog appointment={appointment} />,
    });
  };

  const reportAnIssue = async () => {
    setDialog({
      open: true,
      dialogContent: <ReviewAppointmentAngry appointment={appointment} reviewValue={5} />,
    });
  };

  return (
    <div data-testid="manage-panel">
      <DashedSeparator />
      <Grid container className={styles.detailsPanel}>
        <Grid item xs={12}>
          <Grid container alignItems="center" className={styles.detailsButtonsContainer} spacing={2}>
            <Grid item xs={12} md="auto"></Grid>
            <Grid item xs={12} md>
              <Grid container spacing={1} className={styles.actionButtonsContainer}>
                <Grid item xs={12} md="auto" className={styles.detailsButton}>
                  <Button buttonType="outlined" onClick={() => reportAnIssue()}>
                    Report an Issue
                  </Button>
                </Grid>
                {appointment.type === 'JOB' && appointment.status.name === 'Completed' && (
                  <Grid item xs={12} md="auto" className={styles.detailsButton}>
                    <Button buttonType="twoTone" onClick={() => handleReview()}>
                      {appointment.hasReviews ? `Update Review` : `Review Appointment`}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
