import React, { ReactNode } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import { overrides } from './overrides';
import { palette } from './palette';
import { typography } from './typography';

export const theme = createMuiTheme({
  palette,
  typography,
  overrides: overrides,
});

export const Theme = ({ children }: { children: ReactNode }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
);
