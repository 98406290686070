export const hunkPayErrorLabels = [
  {
    field: 'cardNumber',
    label: 'Card number',
    index: 0,
  },
  {
    field: 'expirationDate',
    label: 'Expiration date',
    index: 1,
  },
  {
    field: 'cvv',
    label: 'CVV',
    index: 2,
  },
];
