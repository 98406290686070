import React, { useEffect, useContext } from 'react';
import { ClientContext } from 'react-fetching-library';
import { useLocation } from 'react-router-dom';

import { useAuthDispatch } from 'hooks/useAuthDispatch/useAuthDispatch';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { setAuthorized, setUnauthorized, setTokens } from '../authActionCreators/authActionCreators';
import { fetchUserAction } from 'api/actions/user/userActions';
import { FetchUserRequest, UserResponse } from 'api/actions/user/userActions.types';
import { useAsyncError } from 'hooks/useAsyncError/useAsyncError';
import { getUrlParams } from '../loginController/LoginController';
import { authStorage } from '../authStorage/AuthStorage';
import { AppRoute } from 'routing/AppRoute.enum';

import { UserControllerProps } from './UserController.types';

export const UserController = ({ children }: UserControllerProps) => {
  const dispatch = useAuthDispatch();
  const { query } = useContext(ClientContext);
  const { token, locationId, accountId } = useAuthState();
  const { search, pathname } = useLocation();
  const throwError = useAsyncError();

  useEffect(() => {
    if (pathname === AppRoute.login) {
      const urlParams = getUrlParams(search);
      if (urlParams) {
        dispatch(
          setTokens({
            accountId: urlParams.accountId,
            locationId: urlParams.locationId,
            token: urlParams.token,
          }),
        );
      } else {
        dispatch(setUnauthorized());
      }
    } else if (authStorage.accountId && authStorage.locationId && authStorage.token) {
      dispatch(
        setTokens({
          accountId: authStorage.accountId,
          locationId: authStorage.locationId,
          token: authStorage.token,
        }),
      );
    } else {
      dispatch(setUnauthorized());
    }
  }, [dispatch, pathname, search]);

  useEffect(() => {
    const fetchUser = async () => {
      if (token && locationId && accountId) {
        const data: FetchUserRequest = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          path: { account_id: accountId, location_id: locationId },
          query: { token: token },
        };
        const { payload, error } = await query<UserResponse>(fetchUserAction(data));
        if (!error && payload?.accounts[0]) {
          return dispatch(setAuthorized(payload.accounts[0]));
        } else {
          dispatch(setUnauthorized());
        }
      }
    };
    fetchUser();
  }, [accountId, dispatch, locationId, query, throwError, token]);

  return <>{children}</>;
};
