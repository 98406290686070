import { Action } from 'react-fetching-library';

import { LocationsRequest } from './LocationsActions.types';

export const fetchLocation = (request: LocationsRequest): Action<LocationsRequest> => {
  const {
    path: { location_id, account_id },
    query: { token },
  } = request;
  return {
    method: 'GET',
    endpoint: `/public/website/locations/${location_id}?account_id=${account_id}&token=${token}`,
  };
};
