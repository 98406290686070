import React from 'react';
import { Typography, Grid } from '@material-ui/core';

import { formatPhoneNumber } from 'utils/formatPhone/FormatPhone';
import { UpdateAppointmentUserButton } from 'app/appointmentManagement/updateAppointmentUserButton/UpdateAppointmentUserButton';

import { AppointmentOwnerProps } from './AppointmentOwner.types';

export const AppointmentOwner = ({ owner, showUpdateButton }: AppointmentOwnerProps) => {
  return (
    <Grid container justify="space-between" spacing={2}>
      <Grid item xs={12} sm={12} md>
        <Typography variant="body1">
          <span>
            {owner.first_name} {owner.last_name}
          </span>
          <br />
          <span>{formatPhoneNumber(owner.phone)}</span>
          <br />
          <span>{owner.email}</span>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md="auto">
        {showUpdateButton && <UpdateAppointmentUserButton user={owner} />}
      </Grid>
    </Grid>
  );
};
