import { makeStyles } from '@material-ui/core';

import { theme } from 'assets/theme/Theme';

export const useStyles = makeStyles({
  gridContainer: {
    height: '100%',
  },
  gridItem: {
    width: '400px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    textAlign: 'center',
  },
  title: {},
  description: {
    marginTop: '24px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '18px',
    },
  },
  form: {
    marginTop: '60px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '25px',
    },
  },
  formControl: {
    marginBottom: '40px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '20px',
    },
  },
  button: {
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  helperContainer: {
    position: 'absolute',
    top: '100%',
  },
});
