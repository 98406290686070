import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  dialogContainer: {
    textAlign: 'center',
    overflowY: 'scroll',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  dialogHeader: {
    width: '100%',
  },
  feedbackInput: {
    width: '100%',
  },
});
