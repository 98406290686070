import { useContext } from 'react';

import { AddressOptionsContext } from 'context/addressOptions/addressOptionsContext/AddressOptionsContext';

export const useAddressOptions = () => {
  const value = useContext(AddressOptionsContext);

  if (value === undefined) {
    throw new Error('AddressOptionsContext value is undefined');
  }

  return value;
};
