import { HunkPayStateType } from '../hunkPayContext/HunkPayContext.types';

import { HunkPayActions, SET_HUNKPAY_PAYMENT_METHODS } from './HunkPayContextReducer.types';

export const HunkPayContextReducer = (state: HunkPayStateType, action: HunkPayActions): HunkPayStateType => {
  switch (action.type) {
    case SET_HUNKPAY_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.payload.paymentMethods,
        isLoading: false,
      };
    default:
      throw new Error();
  }
};
