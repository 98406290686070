import { makeStyles } from '@material-ui/core';

import { colors } from 'assets/theme/palette';

export const useStyles = makeStyles({
  dialogContainer: {
    textAlign: 'center',
    overflowY: 'scroll',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  subtitle: {
    color: colors.orange,
  },
  dialogHeader: {
    width: '100%',
  },
  feedbackInput: {
    width: '100%',
  },
});
