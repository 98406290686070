import { useContext } from 'react';

import { LocationStateType } from 'context/location/locationContext/LocationContext.types';
import { LocationContext } from 'context/location/locationContext/LocationContext';

export const useLocationState = (): LocationStateType => {
  const context = useContext(LocationContext);

  if (context === undefined) {
    throw new Error('useLocationState must be used within a LocationContextController');
  }

  return context;
};
