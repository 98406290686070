import React, { useState, useCallback } from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { useParameterizedQuery } from 'react-fetching-library';
import { resolveAny } from 'dns';

import { findAccount } from 'api/actions/findAccount/FindAccountActions';
import {
  FindAccountResponse,
  FindAccountPayload,
  FindAccountRequest,
} from 'api/actions/findAccount/FindAccountActions.types';
import { useMessagesDispatch } from 'hooks/useMessages/useMessages';

import { useStyles } from './FindAccount.styles';
import { FindAccountForm } from './findAccountForm/FindAccountForm';
import { FindAccountConfirmation } from './findAccountConfirmation/FindAccountConfirmation';

export function FindAccount() {
  const styles = useStyles();
  const setMessage = useMessagesDispatch();
  const [formData, setFormData] = useState<FindAccountPayload>();
  const [isConfirmed, setIsConfirmed] = useState(false);

  const { query, loading: isDuringRequest } = useParameterizedQuery<FindAccountResponse>(findAccount);

  const handleSubmit = useCallback(
    async (body: FindAccountPayload): Promise<string> => {
      setFormData(body);
      const request: FindAccountRequest = {
        payload: body,
      };
      const { error, payload } = await query(request);
      if (!error && payload) {
        if (payload.statuses && payload.statuses[0].account_found) {
          setIsConfirmed(true);
          return '';
        } else if (payload.statuses && !payload.statuses[0].account_found) {
          return payload.statuses[0].message;
        }
      }
      return 'Something went wrong';
    },
    [query],
  );

  const handleResend = useCallback(async () => {
    if (formData) {
      const request: FindAccountRequest = {
        payload: formData,
      };
      const { error, payload } = await query(request);
      if (!error && !!payload) {
        setMessage({ message: `${formData && formData.email ? 'Email' : 'Text message'} re-sent!`, type: 'success' });
      }
    }
  }, [formData, query, setMessage]);

  return (
    <Grid container className={styles.root}>
      <Grid className={styles.content} item xs={12} sm={12} md={6} lg={5} xl={4}>
        {!isConfirmed || !formData ? (
          <FindAccountForm onSubmit={handleSubmit} isDuringRequest={isDuringRequest} />
        ) : (
          <FindAccountConfirmation
            email={formData?.email}
            phone={formData?.phone}
            onResend={handleResend}
            isDuringRequest={isDuringRequest}
          />
        )}
      </Grid>

      <Hidden smDown>
        <Grid item md={6} lg={7} xl={8}>
          <div className={styles.image} />
        </Grid>
      </Hidden>
    </Grid>
  );
}
