import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles({
  dialog: {
    position: 'relative',
  },
  dialogContent: {
    padding: '50px 30px!important',
    overflowY: 'hidden',
    display: 'flex',
  },
  close: {
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
});
