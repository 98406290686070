import { useParameterizedQuery } from 'react-fetching-library';
import { useCallback } from 'react';

import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { useAsyncError } from 'hooks/useAsyncError/useAsyncError';
import { AppointmentsResponse, ConfirmAppointmentRequest } from 'api/actions/appointments/AppointmentsActions.types';
import { confirmAppointment } from 'api/actions/appointments/AppointmentsActions';

export function useConfirmAppointment() {
  const { locationId, token, accountId } = useAuthState();
  const throwError = useAsyncError();
  const { query: confirmAppointmentQuery } = useParameterizedQuery<AppointmentsResponse>(confirmAppointment);

  return useCallback(
    async (isConfirmed: boolean, appointmentId: number) => {
      if (token && locationId && accountId) {
        const request: ConfirmAppointmentRequest = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          path: { location_id: locationId, account_id: accountId, appointment_id: appointmentId },
          query: { token: token },
          payload: {
            account: { id: accountId },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            client_confirmed: isConfirmed,
          },
        };
        const { error, payload } = await confirmAppointmentQuery(request);
        if (!error && payload?.appointments[0]) {
          return payload?.appointments[0].client_confirmed;
        } else {
          throwError(`Error on: ConfirmAppointmentRequest, ${payload?.meta.status.description}`);
        }
      }
      return undefined;
    },
    [accountId, confirmAppointmentQuery, locationId, throwError, token],
  );
}
