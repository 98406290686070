export enum AppRoute {
  home = '/',
  findAccount = '/find-account',
  dashboard = '/dashboard',
  login = '/login',
  cancel = '/cancel',
  reschedule = '/reschedule',
  chhj = '/home',
  booking = '/booking',
}
