import { AddressOption } from '../addressOptionsContext/AddressOptionsContext.types';

export const SET_ADDRESS_OPTIONS = 'SET_ADDRESS_OPTIONS';

export type SetAddressOptions = typeof SET_ADDRESS_OPTIONS;

export type SimpleAction<Type> = {
  type: Type;
};

export type Action<Type, Payload> = SimpleAction<Type> & {
  payload: Payload;
};

export type SetAddressOptionsAction = Action<SetAddressOptions, { addressOptions: AddressOption[] }>;

export type AddressOptionsActions = SetAddressOptionsAction;
