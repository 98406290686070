import { GroupOptionType, SelectOptionType } from '@chhjpackages/components';

export const getSelectValue = <T>(value: T | null, options: (SelectOptionType<T> | GroupOptionType<T>)[]) => {
  const isGroup = (options as GroupOptionType<T>[]).every(opt => opt.options);

  const val = isGroup
    ? (options as GroupOptionType<T>[]).flatMap(option => option.options).find(opt => opt.value === value)
    : (options as SelectOptionType<T>[]).find(opt => opt.value === value);

  return val || null;
};
