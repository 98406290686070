import { LocationStateType } from '../locationContext/LocationContext.types';

import { LocationActions, SET_LOCATION } from './LocationContextReducer.types';

export const LocationContextReducer = (state: LocationStateType, action: LocationActions): LocationStateType => {
  switch (action.type) {
    case SET_LOCATION:
      return {
        ...state,
        location: action.payload.location,
        isLoading: false,
      };
    default:
      throw new Error();
  }
};
