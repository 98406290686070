import React, { useRef } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars';
import Measure from 'react-measure';

import { PaymentForm } from 'ui/paymentForm/PaymentForm';
import { useStyles } from '../AppointmentDetails.styles';
import { Button } from 'ui/button/Button';
import { Appointment } from 'context/appointments/appointmentsContext/AppointmentsContext.types';
import { useAppointmentsDispatch } from 'hooks/useAppointments/useAppointments';
import { SET_RELOAD_APPOINTMENTS } from 'context/appointments/appointmentsContextReducer/AppointmentsContextReducer.types';
import { useMessagesDispatch } from 'hooks/useMessages/useMessages';
import { useDialogDispatch } from 'hooks/useDialog/useDialog';
import { usePayment } from 'hooks/usePayment/usePayment';

export function AppointmentPayment({ appointment }: { appointment: Appointment }) {
  const styles = useStyles();

  const { isHunkPay } = usePayment();

  const appointmentsDispatch = useAppointmentsDispatch();
  const setMessage = useMessagesDispatch();
  const setDialog = useDialogDispatch();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const scrollbarsRef = useRef<any>(null);

  const handlePaymentSuccess = () => {
    setDialog({ open: false });
    appointmentsDispatch({ type: SET_RELOAD_APPOINTMENTS });
    setMessage({ message: 'Payment successful!', type: 'success' });
  };

  const handlePaymentFailed = () => {
    setMessage({ message: 'Payment failed!', type: 'error' });
  };

  const handleOpenDialog = () => {
    setDialog({
      open: true,
      dialogContent: (
        <Scrollbars
          autoHeight
          autoHeightMin="100%"
          autoHeightMax="100%"
          ref={scrollbarsRef}
          renderView={renderProps => (
            <div
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...renderProps}
              style={{
                ...renderProps.style,
                overflowX: 'hidden',
              }}
            />
          )}
          renderTrackHorizontal={() => <div style={{ display: 'none' }} />}
          renderThumbHorizontal={() => <div style={{ display: 'none' }} />}
          autoHideTimeout={800}
          autoHideDuration={200}
        >
          <Measure
            bounds
            onResize={() => {
              scrollbarsRef.current && scrollbarsRef.current.forceUpdate();
            }}
          >
            {({ measureRef }) => (
              <div ref={measureRef} style={{ maxWidth: isHunkPay ? 700 : 446, paddingRight: 0.5 }}>
                <div style={{ maxWidth: '100%', width: 'calc(100vw - 124px)' }}>
                  <PaymentForm
                    appointment={appointment}
                    paymentAmount={appointment.balanceDue}
                    tipEnabled={true}
                    onSuccess={handlePaymentSuccess}
                    onFailed={handlePaymentFailed}
                  />
                </div>
              </div>
            )}
          </Measure>
        </Scrollbars>
      ),
    });
  };

  return (
    <Grid container justify="space-between" spacing={2}>
      <Grid item xs={12} sm={12} md>
        <Typography variant="body2" className={styles.detailsTitle}>
          Balance due
        </Typography>
        <Typography variant="body1" color="textSecondary">
          ${appointment.balanceDue}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md="auto">
        <Button buttonType="filled" size="small" className={styles.paymentButton} onClick={() => handleOpenDialog()}>
          Pay Now
        </Button>
      </Grid>
    </Grid>
  );
}
