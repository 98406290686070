import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';

import { AppointmentCategory } from 'api/types/appointment';
import { usePriceCheck } from 'hooks/usePriceCheck/usePriceCheck';
import { PricingResponseData } from 'api/actions/pricing/PricingActions.types';
import { Loader } from 'ui/loader/Loader';
import { QuoteRatesResponseData } from 'api/actions/quoteRates/QuoteRatesActions.types';
import { useQuoteRates } from 'hooks/useQuoteRates/useQuoteRates';

import { PricesProps } from './Prices.types';
import { useStyles } from './Prices.styles';
import { PriceTrucks } from './priceTrucks/PriceTrucks';
import { PriceTable } from './priceTable/PriceTable';
import { PriceQuoteRates } from './priceQuoteRates/PriceQuoteRates';

export function Prices({ appointment, jobDate, onPricesFetched }: PricesProps) {
  const styles = useStyles();
  const getPrices = usePriceCheck();
  const getQuoteRates = useQuoteRates();
  const [priceList, setPriceList] = useState<PricingResponseData[]>([]);
  const [quoteRates, setQuoteRates] = useState<QuoteRatesResponseData>();
  const [callRequired, setCallRequired] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const isLabor = appointment.category.id === AppointmentCategory.labor;
  const isJunk = appointment.category.id === AppointmentCategory.junk;
  const isExpressJunk = appointment.category.id === AppointmentCategory.expressJunk;
  const isMoving = appointment.category.id === AppointmentCategory.move;
  const isMoveLabor = appointment.category.id === AppointmentCategory.moveLabor;

  useEffect(() => {
    const getPriceData = async () => {
      setIsLoading(true);
      setQuoteRates(undefined);
      setPriceList([]);
      switch (appointment.category.id) {
        case AppointmentCategory.labor:
        case AppointmentCategory.junk:
        case AppointmentCategory.expressJunk:
          setPriceList(await getPrices(appointment, jobDate));
          break;
        case AppointmentCategory.move:
        case AppointmentCategory.moveLabor:
          const rates = await getQuoteRates(appointment, jobDate);
          setQuoteRates(rates);
          setCallRequired(!!rates && rates.call_required);
          break;
      }
      setIsLoading(false);
    };
    getPriceData();
  }, [appointment, getPrices, getQuoteRates, jobDate]);

  useEffect(() => {
    if (!isLoading && onPricesFetched) {
      onPricesFetched({ isCallRequired: callRequired });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callRequired, isLoading]);

  return (
    <>
      {isLoading ? (
        <div style={{ height: '150px' }}>
          <Loader />
        </div>
      ) : !callRequired ? (
        <Grid container justify="center" alignContent="center" direction="column">
          <Grid item xs={12} md={10} className={styles.pricesTitleContainer}>
            <Typography variant="h4" className={styles.pricesTitle}>
              See below for{' '}
              <span className={styles.pricesTitleSpan}>{!isMoving && !isMoveLabor && 'example'} price points</span> for
              our locally owned and operated location servicing your area.
            </Typography>
          </Grid>
          <Grid item xs={12} md={10} className={styles.pricesContainer}>
            {(isJunk || isExpressJunk) && <PriceTrucks priceList={priceList} isExpressJunk={isExpressJunk} />}
            {isLabor && <PriceTable priceList={priceList} />}
            {(isMoving || isMoveLabor) && quoteRates && <PriceQuoteRates quoteRates={quoteRates} />}
          </Grid>
        </Grid>
      ) : (
        <div className={styles.pricesNotAvailable}>
          <Typography variant="body1">Pricing not available</Typography>
          <Typography variant="body1" color="secondary">
            Call us 833-626-1326!
          </Typography>
        </div>
      )}
    </>
  );
}
