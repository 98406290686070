import { makeStyles } from '@material-ui/core';

import { colors } from 'assets/theme/palette';

export const useStyles = makeStyles({
  root: {
    maxWidth: '400px',
    margin: 'auto',
  },
  container: {
    position: 'relative',
    // TODO: fix for !important
    overflow: 'visible!important',
  },
  body: {
    '&::after': {
      content: '" "',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      pointerEvents: 'none',
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
    '& .MuiTableRow-root': {
      verticalAlign: 'initial',
    },
  },
  headerCell: {
    // TODO: fix for !important
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fontWeight: 'bold!important' as any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    textTransform: 'uppercase!important' as any,
    fontSize: '0.875rem!important',
    color: colors.green,
  },
  bodyCell: {
    // TODO: fix for !important
    fontSize: '1.125rem!important',
    color: `${colors.grey}!important`,
  },
});
