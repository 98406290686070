import { useParameterizedQuery } from 'react-fetching-library';
import { useCallback } from 'react';

import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { useAsyncError } from 'hooks/useAsyncError/useAsyncError';
import {
  ReviewAppointmentResponse,
  ReviewAppointmentRequest,
} from 'api/actions/appointments/AppointmentsActions.types';
import { reviewAppointment } from 'api/actions/appointments/AppointmentsActions';

export function useReviewAppointment() {
  const { locationId, token, accountId } = useAuthState();
  const throwError = useAsyncError();
  const { query: reviewAppointmentQuery } = useParameterizedQuery<ReviewAppointmentResponse>(reviewAppointment);

  return useCallback(
    async (sentiment: number, message: string, subject: string, appointmentId: number) => {
      if (token && locationId && accountId) {
        const request: ReviewAppointmentRequest = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          path: {
            location_id: locationId,
            account_id: accountId,
            appointment_id: appointmentId,
          },
          query: { token: token },
          payload: {
            sentiment: sentiment || 0,
            message: message || '',
            subject: subject || '',
          },
        };

        const { error, payload } = await reviewAppointmentQuery(request);

        if (!error && payload?.reviews[0]) {
          return payload?.reviews[0].id;
        } else {
          throwError(`Error on: ReviewAppointmentRequest, ${payload?.meta.status.description}`);
        }
      }
      return undefined;
    },
    [accountId, reviewAppointmentQuery, locationId, throwError, token],
  );
}
