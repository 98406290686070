import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import React from 'react';

import { PriceTableProps } from './PriceTable.types';
import { useStyles } from './PriceTable.styles';

export function PriceTable({ priceList }: PriceTableProps) {
  const columns: { name: string; displayName: string }[] = [
    { name: 'product', displayName: 'Product' },
    { name: 'price', displayName: 'Price' },
  ];
  const styles = useStyles();
  const prices = priceList.filter(p => p.subcategory.name.includes('General Labor') && p.active === 1 && p.price > 0);

  return (
    <div className={styles.root}>
      <TableContainer className={styles.container}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.name} className={styles.headerCell}>
                  {column.displayName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={styles.body}>
            {prices.map((price, index) => (
              <TableRow key={`price-${index}`}>
                <TableCell className={styles.bodyCell}>{price.name}</TableCell>
                <TableCell className={styles.bodyCell}>${price.price}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
