import { useParameterizedQuery } from 'react-fetching-library';
import { useCallback } from 'react';

import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { useAsyncError } from 'hooks/useAsyncError/useAsyncError';
import {
  ReviewAppointmentResponse,
  ReviewAppointmentPlatformClickRequest,
} from 'api/actions/appointments/AppointmentsActions.types';
import { reviewAppointmentPlatformClick } from 'api/actions/appointments/AppointmentsActions';

export function useReviewAppointmentPlatformClick() {
  const { locationId, token, accountId } = useAuthState();
  const throwError = useAsyncError();
  const { query: reviewAppointmentPlatformClickQuery } = useParameterizedQuery<ReviewAppointmentResponse>(
    reviewAppointmentPlatformClick,
  );

  return useCallback(
    async (platform_id: number, appointmentId: number) => {
      if (token && locationId && accountId) {
        const request: ReviewAppointmentPlatformClickRequest = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          path: {
            location_id: locationId,
            account_id: accountId,
            appointment_id: appointmentId,
          },
          query: { token: token },
          payload: {
            platform: { id: platform_id },
          },
        };
        const { error, payload } = await reviewAppointmentPlatformClickQuery(request);
        if (!error && payload?.reviews[0]) {
          return payload?.reviews[0].id;
        } else {
          throwError(`Error on: ReviewAppointmentPlatformClickRequest, ${payload?.meta.status.description}`);
        }
      }
      return undefined;
    },
    [accountId, reviewAppointmentPlatformClickQuery, locationId, throwError, token],
  );
}
