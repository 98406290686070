import { makeStyles } from '@material-ui/core';

import { colors } from 'assets/theme/palette';

export const useStyles = makeStyles(theme => ({
  [theme.breakpoints.up('sm')]: {
    faceSeparatorLeft: {
      width: '50px',
      marginTop: '-25px',
      borderTop: `2px dashed ${colors.grey20}`,
    },
    faceSeparatorRight: {
      width: '50px',
      marginTop: '-25px',
      borderTop: `2px dashed ${colors.grey20}`,
    },
  },
}));
