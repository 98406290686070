import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: '40px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '20px',
    },
  },
  button: {
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  helperContainer: {
    position: 'absolute',
    top: '100%',
  },
}));
