import React, { useState, useEffect } from 'react';
import { Snackbar, Typography } from '@material-ui/core';

import { Alert } from 'ui/alert/Alert';
import { useMessagesDispatch, useMessagesState } from 'hooks/useMessages/useMessages';
import { ScrollToTop } from 'ui/scrollToTop/ScrollToTop';

import { useStyles } from './Message.styles';

export function Message() {
  const styles = useStyles();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { message, type } = useMessagesState();
  const setMessage = useMessagesDispatch();
  const handleCloseSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
    setMessage({ message: '', type: undefined });
  };

  useEffect(() => {
    setOpenSnackbar(true);
  }, [message]);

  return (
    <>
      {message && (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert icon={false} severity={type} onClose={handleCloseSnackbar} className={styles.alert}>
            <ScrollToTop />
            <Typography variant="h4">{message}</Typography>
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
