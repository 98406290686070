import { createContext } from 'react';

import { LocationStateType } from './LocationContext.types';

export const locationStateDefault: LocationStateType = {
  location: null,
  isLoading: true,
};

export const LocationContext = createContext<LocationStateType | undefined>(undefined);
