import { AvailabilityResponseData } from 'api/actions/availability/AvailabilityActions.types';

export const sortAvailableTime = (a: AvailabilityResponseData, b: AvailabilityResponseData) => {
  if (a.schedule.start < b.schedule.start) {
    return -1;
  } else if (a.schedule.start === b.schedule.start) {
    return 0;
  } else {
    return 1;
  }
};
