export const tipOptions = [
  {
    value: 0,
    label: 'No tip',
  },
  {
    value: 0.1,
    label: '10%',
  },
  {
    value: 0.15,
    label: '15%',
  },
  {
    value: 0.2,
    label: '20%',
  },
  {
    value: 'custom',
    label: 'Custom',
  },
];
