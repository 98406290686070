import React from 'react';
import MaskedInput from 'react-text-mask';

export function PhoneMask(props: { inputRef: (ref: HTMLInputElement | null) => void }) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    />
  );
}
