import { createStyles, Theme, withStyles, Select } from '@material-ui/core';

import { colors } from 'assets/theme/palette';

export const SelectStyled = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:not(.Mui-error) .MuiSelect-select': {
        color: colors.grey,
      },
    },
    select: {
      padding: '1.125rem 0.875rem',
      textAlign: 'initial',
    },
    selectMenu: {
      color: colors.grey,
      fontSize: '1.5rem',
      fontWeight: 'normal',
      '& .select-placeholder': {
        color: colors.grey60,
        fontSize: '1.5rem',
        fontWeight: 'normal',
      },
    },
    icon: {
      color: colors.green,
      fontSize: '24px',
    },
  }),
)(Select);
