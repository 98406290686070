import React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export function DollarAmountMask(props: { inputRef: (ref: HTMLInputElement | null) => void }) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={createNumberMask({
        prefix: '$ ',
        suffix: '',
        allowDecimal: true,
        decimalLimit: 2,
        includeThousandsSeparator: false,
        allowLeadingZeroes: true,
      })}
    />
  );
}
