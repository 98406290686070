import { Action } from 'react-fetching-library';

import { FindAccountRequest } from './FindAccountActions.types';

export const findAccount = (request: FindAccountRequest): Action<FindAccountRequest> => {
  return {
    method: 'POST',
    endpoint: '/public/website/accounts/find',
    body: request.payload,
  };
};
