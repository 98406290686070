import React from 'react';
import { Typography, Grid } from '@material-ui/core';

import { useStyles } from '../AppointmentDetails.styles';
import { ConfirmAppointmentComponent } from 'app/appointmentManagement/confirmAppointmentComponent/ConfirmAppointmentComponent';

import { AppointmentStatusProps } from './AppointmentStatus.types';

export const AppointmentStatus = ({ appointment, showConfirmButton }: AppointmentStatusProps) => {
  const styles = useStyles();
  return (
    <Grid container justify="space-between" spacing={2}>
      <Grid item xs={12} sm={12} md>
        <Typography variant="body2" className={styles.detailsTitle}>
          Status
        </Typography>
        <Typography variant="body1">{appointment.status.name}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md="auto">
        {showConfirmButton && <ConfirmAppointmentComponent appointment={appointment} />}
      </Grid>
    </Grid>
  );
};
