import { AppointmentsStateType } from '../appointmentsContext/AppointmentsContext.types';

import {
  AppointmentsContextActions,
  SET_APPOINTMENTS,
  SET_RELOAD_APPOINTMENTS,
} from './AppointmentsContextReducer.types';

export function AppointmentContextReducer(
  state: AppointmentsStateType,
  action: AppointmentsContextActions,
): AppointmentsStateType {
  switch (action.type) {
    case SET_APPOINTMENTS:
      return {
        ...state,
        appointments: action.payload.appointments,
        finishedAppointments: action.payload.finishedAppointments,
        upcomingAppointments: action.payload.upcomingAppointments,
        reload: false,
        isLoading: false,
      };
    case SET_RELOAD_APPOINTMENTS:
      return {
        ...state,
        reload: true,
        isLoading: true,
      };
    default:
      throw new Error();
  }
}
