import { useParameterizedQuery } from 'react-fetching-library';
import { useCallback } from 'react';

import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { useAsyncError } from 'hooks/useAsyncError/useAsyncError';
import { RequestEtaResponse, RequestEtaRequest } from 'api/actions/appointments/AppointmentsActions.types';
import { requestEta } from 'api/actions/appointments/AppointmentsActions';

export function useRequestEta() {
  const { locationId, token, accountId } = useAuthState();
  const throwError = useAsyncError();
  const { query: requestEtaQuery } = useParameterizedQuery<RequestEtaResponse>(requestEta);

  return useCallback(
    async (appointmentId: number) => {
      if (token && locationId && accountId) {
        const request: RequestEtaRequest = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          path: { location_id: locationId, account_id: accountId, appointment_id: appointmentId },
          query: { token: token },
          payload: {
            type: 'eta',
          },
        };
        const { error, payload } = await requestEtaQuery(request);
        if (!error) {
          return true;
        } else {
          throwError(`Error on: RequestEtaRequest, ${payload?.meta.status.description}`);
        }
      }
      return undefined;
    },
    [accountId, requestEtaQuery, locationId, throwError, token],
  );
}
