import { Grid, Typography, Hidden } from '@material-ui/core';
import React from 'react';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';

import { useStyles } from './ConfirmAppointmentDialog.styles';

export function ConfirmAppointmentDialog() {
  const styles = useStyles();
  return (
    <Grid container direction="row" alignItems="center" justify="center" spacing={4} className={styles.dialogContainer}>
      <Grid item>
        <Typography variant="h2">
          Congratulations! <span className={styles.subtitle}>Your appointment has been confirmed</span>
        </Typography>
      </Grid>

      <Hidden smDown>
        <Grid item>
          <CheckCircleOutlineRoundedIcon className={styles.checkIcon} />
        </Grid>
      </Hidden>

      <Grid item>
        <Typography variant="body1" color="primary" className={styles.dialogDescription}>
          Welcome to your online account. Here, you can manage your appointments and review your upcoming appointment
          information.
        </Typography>
      </Grid>
    </Grid>
  );
}
