/* eslint-disable @typescript-eslint/naming-convention */
import { Action } from 'react-fetching-library';

import { QuoteRatesRequest } from './QuoteRatesActions.types';

export const fetchQuoteRates = (request: QuoteRatesRequest): Action<QuoteRatesRequest> => {
  const {
    path: { location_id, account_id },
    query: { token },
    payload,
  } = request;
  return {
    method: 'POST',
    endpoint: `/public/website/locations/${location_id}/accounts/${account_id}/quotes/calculate/rates?token=${token}`,
    body: payload,
  };
};
