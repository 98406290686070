import { Location } from '../locationContext/LocationContext.types';

export const SET_LOCATION = 'SET_LOCATION';

export type SetLocation = typeof SET_LOCATION;

export type SimpleAction<Type> = {
  type: Type;
};

export type Action<Type, Payload> = SimpleAction<Type> & {
  payload: Payload;
};

export type SetLocationAction = Action<SetLocation, { location: Location }>;

export type LocationActions = SetLocationAction;
