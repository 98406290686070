/* eslint-disable @typescript-eslint/naming-convention */
import { Action } from 'react-fetching-library';

import { PricingRequest } from './PricingActions.types';

export const fetchPricing = (request: PricingRequest): Action<PricingRequest> => {
  const {
    path: { location_id },
    query: { token, category_id, job_date, postal },
  } = request;
  return {
    method: 'GET',
    endpoint: `/public/website/locations/${location_id}/viewpricingbyproduct?token=${token}&postal=${postal}&category_id=${category_id}&job_date=${job_date}`,
  };
};
