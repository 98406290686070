import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { colors } from './palette';

export const fontWeight: Record<
  string,
  number | 'lighter' | 'normal' | 'bold' | 'inherit' | 'initial' | 'revert' | 'unset' | 'bolder' | undefined
> = {
  light: 'lighter',
  normal: 'normal',
  bold: 'bold',
};
export const NoahFont = 'Noah';
export const RiftFont = 'Rift Soft';
export const BarlowFont = 'Barlow';

export const Noah: CSSProperties['@font-face'] = {
  fontFamily: NoahFont,
};
export const RiftSoft: CSSProperties['@font-face'] = {
  fontFamily: RiftFont,
};
export const Barlow: CSSProperties['@font-face'] = {
  fontFamily: BarlowFont,
};

export const typography: TypographyOptions = {
  fontFamily: NoahFont,
  h1: {
    fontFamily: RiftFont,
    fontSize: '3rem',
    fontWeight: fontWeight.bold,
  },
  h2: {
    fontFamily: RiftFont,
    fontSize: '2.25rem',
    fontWeight: fontWeight.bold,
  },
  h3: {
    fontFamily: RiftFont,
    fontSize: '1.5rem',
    fontWeight: fontWeight.bold,
  },
  h4: {
    fontFamily: RiftFont,
    fontSize: '1.25rem',
    fontWeight: fontWeight.bold,
  },
  h6: {
    fontFamily: NoahFont,
    fontSize: '0.875rem',
    fontWeight: fontWeight.bold,
  },
  body1: {
    color: colors.grey,
    fontFamily: NoahFont,
    fontSize: 18,
    fontWeight: fontWeight.normal,
    lineHeight: '1.375rem',
  },
  body2: {
    fontSize: 14,
    fontWeight: fontWeight.bold,
    lineHeight: '1.5rem',
  },
  button: {
    fontFamily: RiftFont,
    fontSize: 18,
    fontWeight: fontWeight.bold,
  },
};
