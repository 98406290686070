import React, { useEffect, useState } from 'react';

import { useGeocode } from 'hooks/useGeocode/useGeocode';

import { Map } from './Map';
import { MapContainerProps } from './Map.types';

export function MapContainer({ address, address2, city, state, postal, country }: MapContainerProps) {
  const addressString = [address, address2, city, state, postal, country].filter(Boolean).join(', ');
  const geocode = useGeocode();
  const [location, setLocation] = useState<{
    lat: number;
    lng: number;
  }>();

  useEffect(() => {
    const doGeocode = async () => {
      const location = await geocode(encodeURI(addressString));
      setLocation(location);
    };
    doGeocode();
  }, [addressString, geocode]);

  return <>{location && <Map lat={location.lat} lng={location.lng} />}</>;
}
