import { colors } from '@chhjpackages/components';

export const cardStyles = {
  base: {
    color: colors.secondary.main,
    fontSize: '17px',
    fontSmoothing: 'antialiased',
    fontFamily: 'sans-serif',
    fontWeight: 500,
  },
  error: {
    color: colors.functionals.alert,
  },
  placeholder: {
    fontWeight: 500,
  },
  validated: {
    color: colors.secondary.main,
  },
};
