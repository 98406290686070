import React, { useState } from 'react';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';

import { useAppointmentsDispatch } from 'hooks/useAppointments/useAppointments';
import { useConfirmAppointment } from 'hooks/useConfirmAppointment/useConfirmAppointment';
import { SET_RELOAD_APPOINTMENTS } from 'context/appointments/appointmentsContextReducer/AppointmentsContextReducer.types';
import { Button } from 'ui/button/Button';
import { useDialogDispatch } from 'hooks/useDialog/useDialog';

import { ConfirmAppointmentComponentProps } from './ConfirmAppointmentComponent.types';
import { useStyles } from './ConfirmAppointmentComponent.styles';
import { ConfirmAppointmentDialog } from './confirmAppointmentDialog/ConfirmAppointmentDialog';

export function ConfirmAppointmentComponent({ appointment }: ConfirmAppointmentComponentProps) {
  const styles = useStyles();
  const [isDuringRequest, setIsDuringRequest] = useState(false);
  const confirmAppointment = useConfirmAppointment();
  const appointmentsDispatch = useAppointmentsDispatch();
  const setDialog = useDialogDispatch();

  const handleConfirm = async () => {
    setIsDuringRequest(true);

    const isConfirmed = await confirmAppointment(true, appointment.id);
    if (isConfirmed) {
      setDialog({ open: true, dialogContent: <ConfirmAppointmentDialog /> });
      appointmentsDispatch({ type: SET_RELOAD_APPOINTMENTS });
    }
  };

  return (
    <div>
      {!appointment.clientConfirmed ? (
        <Button
          buttonType="filled"
          size="small"
          className={styles.button}
          onClick={() => handleConfirm()}
          isLoading={isDuringRequest}
        >
          Confirm time
        </Button>
      ) : (
        <CheckCircleOutlineRoundedIcon />
      )}
    </div>
  );
}
