import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { PriceMovingProps } from './PriceQuoteRates.types';
import { useStyles } from './PriceQuoteRates.styles';

export function PriceQuoteRates({ quoteRates }: PriceMovingProps) {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Grid container spacing={4} className={styles.container}>
        <Grid item xs={12} md={4}>
          <Typography variant="h2" color="secondary">
            {quoteRates.minimum_hours}
          </Typography>
          <Typography variant="h4" color="textSecondary">
            Minimum hours
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="h2" color="secondary">
            ${quoteRates.hourly_rate}
          </Typography>
          <Typography variant="h4" color="textSecondary">
            Hourly rate
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="h2" color="secondary">
            ${quoteRates.truck_and_travel}
          </Typography>
          <Typography variant="h4" color="textSecondary">
            Truck and travel fee
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
