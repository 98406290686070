import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { IconButton, TableCell } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

import { displayDateTime } from 'utils/formatDate/FormatDate';
import { useStyles } from '../AppointmentsTableContainer.styles';
import { AppointmentType } from 'api/types/appointment';

import { AppointmentCellProps } from './AppointmentCell.types';

export const AppointmentCell = ({ appointment, column, onExpand, className }: AppointmentCellProps) => {
  const styles = useStyles();
  let content: React.ReactNode;
  let colSpan = 1;
  let align: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined = 'left';
  switch (column) {
    case 'id':
      content = <>#{appointment.id}</>;
      break;
    case 'startDate':
      content = <>{displayDateTime(appointment.startDate)}</>;
      break;
    case 'endDate':
      content = <>{displayDateTime(appointment.endDate)}</>;
      break;
    case 'category':
      content = <>{appointment.category.name}</>;
      break;
    case 'type':
      content = <>{AppointmentType[appointment.type]}</>;
      break;
    case 'status':
      content = <>{appointment.status.name}</>;
      break;
    case 'expand':
      content = (
        <IconButton
          className={styles.expandIcon}
          color="secondary"
          onClick={() => onExpand(appointment.id)}
          disableRipple
        >
          <KeyboardArrowDownRoundedIcon />
        </IconButton>
      );
      colSpan = 2;
      align = 'center';
      break;
    default:
      content = <></>;
  }
  return (
    <TableCell
      colSpan={colSpan}
      align={align}
      className={clsx(className, column === 'expand' && styles.expandIconCell)}
    >
      {content}
    </TableCell>
  );
};
