import React from 'react';

export const useAsyncError = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setError] = React.useState();
  return React.useCallback(
    (e: string) => {
      setError(() => {
        throw new Error(e);
      });
    },
    [setError],
  );
};
