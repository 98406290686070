import { Action } from 'api/types';

import { FetchUserRequest, UpdateUserRequest } from './userActions.types';

export function fetchUserAction(request: FetchUserRequest): Action<FetchUserRequest> {
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    path: { location_id, account_id },
    query: { token },
  } = request;

  return {
    method: 'GET',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    endpoint: `/public/website/locations/${location_id}/accounts/${account_id}/?token=${token}`,
    ...(token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {}),
  };
}

export function updateUserAction(request: UpdateUserRequest): Action<UpdateUserRequest> {
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    path: { location_id, account_id },
    query: { token },
    payload,
  } = request;

  return {
    method: 'PUT',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    endpoint: `/public/website/locations/${location_id}/accounts/${account_id}/?token=${token}`,
    body: payload,
  };
}
