import { Typography, Grid } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

import { PriceTrucksProps } from './PriceTrucks.types';
import { useStyles } from './PriceTrucks.styles';

export function PriceTrucks({ priceList, isExpressJunk }: PriceTrucksProps) {
  const styles = useStyles();

  const minimumTruckPriceId = !isExpressJunk ? 3 : 15634;
  const halfTruckPriceId = !isExpressJunk ? 20 : 15639;
  const fullTruckPriceId = !isExpressJunk ? 46 : 15644;
  const minimumTruckPrice = priceList.find(p => p.id === minimumTruckPriceId)?.price;
  const halfTruckPrice = priceList.find(p => p.id === halfTruckPriceId)?.price;
  const fullTruckPrice = priceList.find(p => p.id === fullTruckPriceId)?.price;

  return (
    <div className={styles.root}>
      <Grid container spacing={4} className={styles.trucksContainer}>
        <Grid item xs={12} md={4}>
          <div className={clsx(styles.truck, styles.minimumTruck)} />
          <Typography variant="body2" color="textSecondary" className={styles.loadTitle}>
            Less than 1/8th truckload
          </Typography>
          {minimumTruckPrice ? (
            <Typography variant="h4" color="secondary">
              approx. ${minimumTruckPrice}
            </Typography>
          ) : (
            <Typography variant="body2" className={styles.notAvailable}>
              Price not available
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={clsx(styles.truck, styles.halfTruck)} />
          <Typography variant="body2" color="textSecondary" className={styles.loadTitle}>
            1/2th truckload
          </Typography>
          {halfTruckPrice ? (
            <Typography variant="h4" color="secondary">
              approx. ${halfTruckPrice}
            </Typography>
          ) : (
            <Typography variant="body2" className={styles.notAvailable}>
              Price not available
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={clsx(styles.truck, styles.fullTruck)} />
          <Typography variant="body2" color="textSecondary" className={styles.loadTitle}>
            Full truckload
          </Typography>
          {fullTruckPrice ? (
            <Typography variant="h4" color="secondary">
              approx. ${fullTruckPrice}
            </Typography>
          ) : (
            <Typography variant="body2" className={styles.notAvailable}>
              Price not available
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
