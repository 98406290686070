import { Action } from 'react-fetching-library';

import { GeocodeRequest } from './GeocodeActions.types';

export const geocode = (request: GeocodeRequest): Action<GeocodeRequest> => {
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    path: { account_id },
    query: { token, addressToGeocode },
  } = request;
  return {
    method: 'GET',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    endpoint: `/public/website/geocoder?account_id=${account_id}&token=${token}&address=${addressToGeocode}`,
    cache: 'force-cache',
  };
};
