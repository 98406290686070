import React from 'react';
import { CalendarProps } from '@material-ui/pickers/views/Calendar/Calendar';
import { isSameDay } from 'date-fns';

import { CalendarStyled, useStyles } from './Calendar.styles';

export function Calendar({ ...props }: Pick<CalendarProps, 'onChange' | 'date'>) {
  const styles = useStyles();
  return (
    <div className={styles.datePicker}>
      <CalendarStyled
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        disablePast
        shouldDisableDate={day => isSameDay(new Date(), day as Date)}
      />
    </div>
  );
}
