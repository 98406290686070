import { ReactNode, useEffect, useReducer } from 'react';
import { useParameterizedQuery } from 'react-fetching-library';

import { fetchStatesAction } from 'api/actions/states/StatesActions';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { StatesRequest, StatesResponse } from 'api/actions/states/StatesActions.types';
import { useAsyncError } from 'hooks/useAsyncError/useAsyncError';
import { AddressOptionsContext, addressOptionsStateDefault } from '../addressOptionsContext/AddressOptionsContext';
import { AddressOption } from '../addressOptionsContext/AddressOptionsContext.types';
import { AddressOptionsContextReducer } from '../addressOptionsContextReducer/AddressOptionsContextReducer';
import { SET_ADDRESS_OPTIONS } from '../addressOptionsContextReducer/AddressOptionsContextReducer.types';

export const AddressOptionsContextController = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useReducer(AddressOptionsContextReducer, addressOptionsStateDefault);

  const { accountId, token, isAuthorized } = useAuthState();
  const throwError = useAsyncError();

  const { query: getStatesQuery } = useParameterizedQuery<StatesResponse>(fetchStatesAction);

  useEffect(() => {
    const getStates = async () => {
      if (token && accountId) {
        const request: StatesRequest = {
          path: { account_id: accountId },
          query: { token: token },
        };

        const { error, payload } = await getStatesQuery(request);

        if (!error && !!payload && payload.states) {
          setState({
            type: SET_ADDRESS_OPTIONS,
            payload: {
              addressOptions: payload.states.reduce<AddressOption[]>((acc, item) => {
                let group = acc.find(g => g.countryValue === item.country);
                if (!group) {
                  group = {
                    countryName: item.country_full,
                    countryValue: item.country,
                    states: [],
                  };
                  acc.push(group);
                }
                group.states.push({ name: item.state_full, value: item.state });
                return acc;
              }, []),
            },
          });
        } else {
          throwError(`Error on: StatesRequest, ${payload?.meta.status.description}`);
        }
      }
    };

    if (isAuthorized) {
      getStates();
    }
  }, [isAuthorized, token, accountId, throwError, getStatesQuery]);

  return <AddressOptionsContext.Provider value={state}>{children}</AddressOptionsContext.Provider>;
};
