import { ReactNode, useEffect, useReducer } from 'react';
import { useParameterizedQuery } from 'react-fetching-library';

import { HunkPayStateContext, hunkPayStateDefault } from '../hunkPayContext/HunkPayContext';
import { HunkPayContextReducer } from '../hunkPayContextReducer/HunkPayContextReducer';
import { HunkPayPaymentMethodsRequest, HunkPayPaymentMethodsResponse } from 'api/actions/payment/PaymentActions.types';
import { fetchHunkPayPaymentMethods } from 'api/actions/payment/PaymentActions';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { SET_HUNKPAY_PAYMENT_METHODS } from '../hunkPayContextReducer/HunkPayContextReducer.types';
import { useAsyncError } from 'hooks/useAsyncError/useAsyncError';
import { usePayment } from 'hooks/usePayment/usePayment';

export const HunkPayContextController = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useReducer(HunkPayContextReducer, hunkPayStateDefault);

  const { locationId, isAuthorized, token, accountId } = useAuthState();
  const { isHunkPay } = usePayment();
  const throwError = useAsyncError();

  const { query: getHunkPayPaymentMethodsQuery } = useParameterizedQuery<HunkPayPaymentMethodsResponse>(
    fetchHunkPayPaymentMethods,
  );

  useEffect(() => {
    const getHunkPayPaymentMethods = async () => {
      if (locationId && token && accountId) {
        const request: HunkPayPaymentMethodsRequest = {
          path: { location_id: locationId, account_id: accountId },
          query: { token: token },
        };

        const { error, payload } = await getHunkPayPaymentMethodsQuery(request);

        if (!error && !!payload && payload.payment_methods) {
          setState({
            type: SET_HUNKPAY_PAYMENT_METHODS,
            payload: {
              paymentMethods: payload.payment_methods,
            },
          });
        } else {
          throwError(`Error on: PaymentMethodsRequest, ${payload?.meta.status.description}`);
        }
      }
    };

    if (isAuthorized && isHunkPay) {
      getHunkPayPaymentMethods();
    }
  }, [isAuthorized, locationId, token, accountId, isHunkPay, getHunkPayPaymentMethodsQuery, throwError]);

  return <HunkPayStateContext.Provider value={state}>{children}</HunkPayStateContext.Provider>;
};
