import { makeStyles } from '@material-ui/core';

import { colors } from 'assets/theme/palette';

export const useStyles = makeStyles({
  pricesTitle: {
    textAlign: 'center',
  },
  pricesTitleSpan: {
    color: colors.orange,
  },
  pricesNotAvailable: {
    margin: '40px 0',
  },
  pricesTitleContainer: {
    marginBottom: '30px',
  },
  pricesContainer: {
    marginBottom: '20px',
  },
});
