import { Grid, Typography, FormControl, FormHelperText } from '@material-ui/core';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { TextArea } from '@chhjpackages/components';

import { ReviewFormData } from '../ReviewAppointmentDialog.types';
import { Button } from 'ui/button/Button';
import { useReviewAppointment } from 'hooks/useReviewAppointment/useReviewAppointment';
import { ReviewAppointmentThankYou } from '../reviewAppointmentThankYou/ReviewAppointmentThankYou';
import { useDialogDispatch } from 'hooks/useDialog/useDialog';

import { ReviewAppointmentNeutralProps } from './ReviewAppointmentNeutral.types';
import { useStyles } from './ReviewAppointmentNeutral.styles';

export function ReviewAppointmentNeutral({ appointment, reviewValue }: ReviewAppointmentNeutralProps) {
  const styles = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, register, errors } = useForm<ReviewFormData>();
  const reviewAppointment = useReviewAppointment();
  const setDialog = useDialogDispatch();

  const submit = async (values: ReviewFormData) => {
    setIsLoading(true);
    await reviewAppointment(reviewValue, values.message, '', appointment.id);
    setDialog({
      open: true,
      dialogContent: <ReviewAppointmentThankYou appointment={appointment} reviewType={'neutral'} />,
    });
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Grid container direction="row" alignItems="center" spacing={2} className={styles.dialogContainer}>
        <Grid item xs={12}>
          <Typography variant="h3">We'd love to hear more from you</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={styles.feedbackInput} error={!!errors.message?.message}>
            <TextArea
              size={'large'}
              variant={'outlined'}
              fullWidth
              placeholder={'What could have been done better?'}
              rows={5}
              name="message"
              inputRef={register({
                required: 'This field is required',
                minLength: {
                  value: 5,
                  message: 'This field should have at least 5 characters',
                },
              })}
            />
            <div>
              <FormHelperText>{errors.message && errors.message.message}</FormHelperText>
            </div>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button buttonType="twoTone" type="submit" isLoading={isLoading}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
