import { createStyles, Theme, withStyles, Input, makeStyles } from '@material-ui/core';

import { colors } from 'assets/theme/palette';

export const useStyles = (size: 'small' | 'medium' | 'large') => {
  const fontSize = size === 'small' ? '16px' : size === 'medium' ? '20px' : '24px';
  const padding = size === 'small' ? '6px' : size === 'medium' ? '8px' : '10px';
  const labelTransform =
    size === 'small'
      ? 'translate(6px, 24px) scale(1)'
      : size === 'medium'
      ? 'translate(8px, 30px) scale(1)'
      : 'translate(10px, 32px) scale(1)';

  const style = makeStyles({
    root: {
      '& ::placeholder': {
        fontSize: fontSize,
      },
      '& input': {
        padding: padding,
        fontSize: fontSize,
      },
      '& .MuiInputLabel-root': {
        fontSize: fontSize,
        '&:not(.MuiInputLabel-shrink)': {
          transform: labelTransform,
        },
      },
    },
  });
  return style();
};

export const InputStyled = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& ::placeholder': {
        color: colors.grey60,
        opacity: 1,
        fontWeight: 'normal',
      },
    },
    input: {
      color: colors.grey,
      fontWeight: 'normal',
    },
  }),
)(Input);
