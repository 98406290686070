import { Action } from 'react-fetching-library';

import { ServiceCheckRequest } from './ServiceCheckActions.types';

export const checkServiceLocation = (request: ServiceCheckRequest): Action<ServiceCheckRequest> => {
  return {
    method: 'POST',
    endpoint: '/public/website/servicecheck',
    body: request.payload,
  };
};
