import { Action } from 'react-fetching-library';

import { HunkPayPaymentMethodsRequest, HunkPayPaymentRequest, PaymentRequest } from './PaymentActions.types';

export const squarePayment = (request: PaymentRequest): Action<PaymentRequest> => {
  const {
    path: { account_id, location_id, appointment_id },
    query: { token },
    payload,
  } = request;
  return {
    method: 'POST',
    endpoint: `/public/website/locations/${location_id}/accounts/${account_id}/appointments/${appointment_id}/payments/square?token=${token}`,
    body: payload,
  };
};

export const fetchHunkPayPaymentMethods = (
  request: HunkPayPaymentMethodsRequest,
): Action<HunkPayPaymentMethodsRequest> => {
  const {
    path: { location_id, account_id },
    query: { token },
  } = request;
  return {
    method: 'GET',
    endpoint: `/public/website/locations/${location_id}/hunkpay/stores/payments/${account_id}/methods?token=${token}`,
  };
};

export const hunkPayPayment = (request: HunkPayPaymentRequest): Action<HunkPayPaymentRequest> => {
  const {
    path: { location_id, appointment_id, account_id },
    query: { token },
    payload,
  } = request;
  return {
    method: 'POST',
    endpoint: `/public/website/locations/${location_id}/hunkpay/stores/${account_id}/appointments/${appointment_id}/payments?token=${token}`,
    body: payload,
  };
};
