import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import qs from 'qs';

import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { Loader } from 'ui/loader/Loader';
import { AppRoute } from 'routing/AppRoute.enum';
import { useMessagesDispatch } from 'hooks/useMessages/useMessages';
import { useConfirmAppointment } from 'hooks/useConfirmAppointment/useConfirmAppointment';

import { MagicLinkParams } from './LoginController.types';

export const LoginController = () => {
  const { isAuthorized, isAuthorizing } = useAuthState();
  const { search } = useLocation();
  const setMessage = useMessagesDispatch();
  const confirmAppointment = useConfirmAppointment();
  const [isInitialized, setIsInitialized] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [beginReview, setBeginReview] = useState(false);
  const [makePayment, setMakePayment] = useState(false);

  const urlParams = useMemo(() => {
    return getUrlParams(search);
  }, [search]);

  useEffect(() => {
    const confirm = async () => {
      if (urlParams?.beginReview && urlParams.appointmentId) {
        setBeginReview(true);
      }
      if (urlParams?.makePayment && urlParams.appointmentId) {
        setMakePayment(true);
      }
      if (urlParams?.confirmAppointment && urlParams.appointmentId && confirmAppointment) {
        const confirmed = await confirmAppointment(true, urlParams.appointmentId);
        if (confirmed) {
          setIsConfirmed(true);
        }
      }
      setIsInitialized(true);
    };
    if (!isAuthorizing && isAuthorized) {
      confirm();
    }
  }, [beginReview, makePayment, confirmAppointment, isAuthorized, isAuthorizing, urlParams]);

  if (isInitialized) {
    return (
      <Redirect
        to={{
          pathname: AppRoute.dashboard,
          state: {
            appointmentId: urlParams?.appointmentId,
            isConfirmed: isConfirmed,
            beginReview: beginReview,
            makePayment: makePayment,
            paymentAmount: urlParams?.paymentAmount,
            tipEnabled: urlParams?.tipEnabled,
          },
        }}
      />
    );
  }

  if (!isAuthorizing && !isAuthorized) {
    setMessage({ message: 'Your magic link is not valid', type: 'warning' });
    return <Redirect to={AppRoute.findAccount} />;
  }
  return <Loader />;
};

export const getUrlParams = (search: string) => {
  const urlParams = qs.parse(search, { ignoreQueryPrefix: true }) as Record<string, string>;
  if (!urlParams || !urlParams.account_id || !urlParams.location_id || !urlParams.token) {
    return;
  }
  const params: MagicLinkParams = {
    accountId: parseInt(urlParams.account_id),
    locationId: parseInt(urlParams.location_id),
    token: urlParams.token,
    appointmentId: urlParams.appointment_id ? parseInt(urlParams.appointment_id) : undefined,
    confirmAppointment: urlParams.action === 'confirm_appointment',
    beginReview: urlParams.action === 'review_appointment',
    makePayment: urlParams.action === 'make_payment',
    paymentAmount: parseFloat(urlParams.payment_amount) || 0,
    tipEnabled: urlParams.tip_enabled === '0' ? false : true,
  };
  return params;
};
