import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { ClientContextController } from 'context/client/clientContextController/ClientContextController';
import { AuthContextController } from 'context/auth/authContextController/AuthContextController';
import { Theme } from 'assets/theme/Theme';
import { AppointmentsContextController } from 'context/appointments/appointmentsContextController/AppointmentsContextController';
import { UserController } from 'context/auth/userController/UserController';
import { MessagesProvider } from 'hooks/useMessages/useMessages';
import { ErrorBoundary } from 'ui/errorBoundary/ErrorBoundary';
import { DialogProvider } from 'hooks/useDialog/useDialog';
import { HunkPayContextController } from 'context/hunkPay/hunkPayContextController/HunkPayContextController';
import { AddressOptionsContextController } from 'context/addressOptions/addressOptionsContextController/AddressOptionsContextController';
import { LocationContextController } from 'context/location/locationContextController/LocationContextController';

import { AppProvidersProps } from './AppProviders.types';

export const AppProviders = ({ children }: AppProvidersProps) => (
  <ErrorBoundary>
    <Theme>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <AuthContextController>
          <ClientContextController>
            <Router>
              <UserController>
                <LocationContextController>
                  <AppointmentsContextController>
                    <AddressOptionsContextController>
                      <HunkPayContextController>
                        <DialogProvider>
                          <MessagesProvider>{children}</MessagesProvider>
                        </DialogProvider>
                      </HunkPayContextController>
                    </AddressOptionsContextController>
                  </AppointmentsContextController>
                </LocationContextController>
              </UserController>
            </Router>
          </ClientContextController>
        </AuthContextController>
      </MuiPickersUtilsProvider>
    </Theme>
  </ErrorBoundary>
);
