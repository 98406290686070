import { isCanadaPostal, isUSAPostal } from '@chhjpackages/components';
import * as yup from 'yup';

import { AccountDataFormValues } from './AccountDataForm.types';

export const accountDataFormSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .required('First name is required'),
  lastName: yup
    .string()
    .trim()
    .required('Last name is required'),
  address: yup
    .string()
    .trim()
    .required('*required'),
  address2: yup.string().trim(),
  city: yup
    .string()
    .trim()
    .required('City is required'),
  state: yup.string().required('State is required'),
  postal: yup
    .string()
    .trim()
    .required('Zip/postal code is required')
    .test('validator-postalcode', (postal, { createError, path }) => {
      if (!postal) {
        return false;
      }

      const isUSA = isUSAPostal(postal);
      const isCanada = isCanadaPostal(postal);

      if (!isUSA && !isCanada) {
        return createError({ message: 'Must be a valid zip/postal', path });
      }

      return true;
    }),
  country: yup.string().required('Country is required'),
});

export const accountDataErrorLabels: { field: keyof AccountDataFormValues; label: string; index: number }[] = [
  {
    field: 'firstName',
    label: 'First name',
    index: 0,
  },
  {
    field: 'lastName',
    label: 'Last name',
    index: 1,
  },
  {
    field: 'address',
    label: 'Address',
    index: 2,
  },
  {
    field: 'city',
    label: 'City',
    index: 3,
  },
  {
    field: 'state',
    label: 'State',
    index: 4,
  },
  {
    field: 'postal',
    label: 'Zip/postal',
    index: 5,
  },
  {
    field: 'country',
    label: 'Conutry',
    index: 6,
  },
];
