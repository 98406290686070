import { makeStyles } from '@material-ui/core';

import { theme } from 'assets/theme/Theme';

export const useStyles = makeStyles({
  root: {
    padding: '40px 20px',
    width: '100%',
  },
  container: {
    position: 'relative',
  },
  closeContainer: {
    position: 'absolute',
    top: '10px',
    left: '-71px',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      left: 0,
    },
  },
  titleContainer: {
    '& > *': {
      display: 'inline-block',
    },
  },
  title: {
    marginRight: '10px',
  },
  subtitle: {},
  description: {},
  logoutContainer: {
    float: 'right',
    top: '10px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'right',
      marginBottom: '20px',
    },
  },
});
