import React, { createContext, useState, Dispatch, SetStateAction } from 'react';

import { Dialog } from 'ui/dialog/Dialog';

import { DialogProviderProps, DialogContextStateType } from './DialogContext.types';

export const DialogContextState = createContext<DialogContextStateType | undefined>(undefined);
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const DialogContextDispatch = createContext<Dispatch<SetStateAction<DialogContextStateType>>>(() => {});

export const DialogProvider = ({ children }: DialogProviderProps) => {
  const [dialog, setDialog] = useState<DialogContextStateType>({ open: false });
  const { open, dialogContent } = dialog;

  const onClose = () => {
    setDialog({ open: false });
  };

  return (
    <DialogContextState.Provider value={dialog}>
      <DialogContextDispatch.Provider value={setDialog}>
        <Dialog open={open} onClose={onClose}>
          {dialogContent}
        </Dialog>
        {children}
      </DialogContextDispatch.Provider>
    </DialogContextState.Provider>
  );
};
