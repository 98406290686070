import React, { useState } from 'react';
import { Typography, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Redirect } from 'react-router-dom';

import { Logout } from 'app/logout/Logout';
import { Button } from 'ui/button/Button';
import { AppRoute } from 'routing/AppRoute.enum';
import { ScrollToTop } from 'ui/scrollToTop/ScrollToTop';
import { DashboardProps } from 'app/dashboard/Dashboard.types';

import { useStyles } from './PageContent.styles';
import { PageContentProps } from './PageContent.types';

export const PageContent = ({ title, subtitle, description, closable, children, appointmentId }: PageContentProps) => {
  const [redirect, setRedirect] = useState(false);
  const styles = useStyles();

  if (redirect) {
    return (
      <Redirect to={{ pathname: AppRoute.dashboard, state: { appointmentId: appointmentId } as DashboardProps }} />
    );
  }

  return (
    <div className={styles.root} data-testid="page-content">
      <ScrollToTop />
      <Grid container justify="center">
        <Grid item xs={12} sm={8} md={10} lg={8} xl={6}>
          <div className={styles.container}>
            {closable && (
              <div className={styles.closeContainer}>
                <Button buttonType="twoTone" size="medium" onClick={() => setRedirect(true)}>
                  <CloseIcon />
                </Button>
              </div>
            )}
            <div className={styles.logoutContainer}>
              <Logout />
            </div>
            <div className={styles.titleContainer}>
              <Typography className={styles.title} variant="h1">
                {title}
              </Typography>
              {subtitle && (
                <>
                  <span> </span>
                  <Typography className={styles.subtitle} variant="h1" color="secondary">
                    {subtitle}
                  </Typography>
                </>
              )}
            </div>
          </div>
          {description && (
            <Typography className={styles.description} variant="body1">
              {description}
            </Typography>
          )}
          {children}
        </Grid>
      </Grid>
    </div>
  );
};
