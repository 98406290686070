import React from 'react';
import { Redirect } from 'react-router-dom';

import { logout } from 'context/auth/authActionCreators/authActionCreators';
import { authStorage } from 'context/auth/authStorage/AuthStorage';
import { useAuthDispatch } from 'hooks/useAuthDispatch/useAuthDispatch';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { AppRoute } from 'routing/AppRoute.enum';
import { Button } from 'ui/button/Button';

export const Logout: React.FC = ({ children }) => {
  const dispatch = useAuthDispatch();
  const { isAuthorized, isAuthorizing, token, locationId, accountId } = useAuthState();

  const handleLogout = () => {
    authStorage.token = null;
    authStorage.locationId = null;
    authStorage.accountId = null;
    dispatch(logout());
  };

  if (!isAuthorized && !isAuthorizing && (!token || !locationId || !accountId)) {
    return <Redirect to={AppRoute.chhj} />;
  }

  return (
    <Button buttonType="outlined" onClick={handleLogout}>
      Logout
    </Button>
  );
};
