import { useParameterizedQuery } from 'react-fetching-library';
import { useCallback } from 'react';
import { format } from 'date-fns';

import { Appointment } from 'context/appointments/appointmentsContext/AppointmentsContext.types';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { PricingResponse, PricingRequest } from 'api/actions/pricing/PricingActions.types';
import { fetchPricing } from 'api/actions/pricing/PricingActions';
import { useAsyncError } from 'hooks/useAsyncError/useAsyncError';

export function usePriceCheck() {
  const { locationId, token } = useAuthState();
  const throwError = useAsyncError();
  const { query: fetchPricingQuery } = useParameterizedQuery<PricingResponse>(fetchPricing);
  return useCallback(
    async (appointment: Appointment, date: Date) => {
      if (token && locationId) {
        const request: PricingRequest = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          path: { location_id: locationId },
          query: {
            token: token,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            category_id: appointment.category.id,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            job_date: format(date, 'yyyy-MM-dd'),
            postal: appointment.origin.postal,
          },
        };
        const { error, payload } = await fetchPricingQuery(request);
        if (!error && payload?.viewpricingbyproduct) {
          return payload.viewpricingbyproduct;
        } else {
          throwError(`Error on: PricingRequest, ${payload?.meta.status.description}`);
        }
      }
      return [];
    },
    [fetchPricingQuery, locationId, throwError, token],
  );
}
