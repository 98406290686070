import { memo } from 'react';
import { Tooltip as TooltipMui } from '@material-ui/core';

import { TooltipProps } from './Tooltip.types';
import { useStyles } from './Tooltip.styles';

export const Tooltip = memo(({ children, placement, ...rest }: TooltipProps) => {
  const styles = useStyles();

  return (
    <TooltipMui
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...rest}
      placement={placement}
      classes={{
        tooltip: styles.tooltip,
        arrow: styles.arrow,
        tooltipArrow: styles.tooltipArrow,
      }}
    >
      {children}
    </TooltipMui>
  );
});
