import React from 'react';

import { Button } from 'ui/button/Button';
import { Appointment } from 'context/appointments/appointmentsContext/AppointmentsContext.types';
import { useDialogDispatch } from 'hooks/useDialog/useDialog';

import { CheckEtaDialog } from './checkEtaDialog/CheckEtaDialog';

export const CheckEtaButton = ({ appointment }: { appointment: Appointment }) => {
  const setDialog = useDialogDispatch();

  const handleCheckEta = async () => {
    setDialog({ open: true, dialogContent: <CheckEtaDialog appointment={appointment} /> });
  };

  return (
    <Button buttonType="twoTone" onClick={() => handleCheckEta()}>
      Check ETA
    </Button>
  );
};
