import { useContext } from 'react';

import {
  AppointmentsStateContext,
  AppointmentsDispatchContext,
} from 'context/appointments/appointmentsContext/AppointmentsContext';

export const useAppointmentsState = () => {
  const context = useContext(AppointmentsStateContext);
  if (context === undefined) {
    throw new Error('useAppointmentsState must be used within a AppointmentsContextController');
  }
  return context;
};
export const useAppointmentsDispatch = () => {
  const context = useContext(AppointmentsDispatchContext);
  if (context === undefined) {
    throw new Error('useAppointmentsDispatch must be used within a AppointmentsContextController');
  }
  return context;
};
