import { format, isSameDay, isPast } from 'date-fns';

export const displayDate = (date: Date): string => {
  const formattedDate = format(date, 'MM/dd/yyyy');
  return formattedDate;
};

export const displayDateTime = (date: Date): string => {
  const formattedDate = format(date, 'MM/dd/yyyy p');
  return formattedDate;
};

export const dateSameOrPast = (date: Date) => {
  const dayNow = new Date();
  const dayCheck = new Date(date.getTime());
  return isPast(dayCheck) || isSameDay(dayCheck, dayNow);
};

export const dateSame = (date: Date) => {
  const dayNow = new Date();
  const dayCheck = new Date(date.getTime());
  return isSameDay(dayCheck, dayNow);
};

export const datePast = (date: Date) => {
  const dayCheck = new Date(date.getTime());
  return isPast(dayCheck);
};
