import { makeStyles } from '@material-ui/core';

import { colors } from 'assets/theme/palette';

export const useStyles = makeStyles(theme => ({
  face: {
    width: '80px',
    height: '80px',
  },
  faceContainer: {
    padding: '5px',
    margin: 'auto',
    color: colors.grey,
    [theme.breakpoints.up('md')]: {
      padding: '20px',
    },
  },
  dialogContent: {
    padding: '80px 1px!important',
  },
  numPoint: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    position: 'relative',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  recommendReviewNote: {
    color: '#226937',
    fontWeight: 600,
    fontSize: '1.2rem',
  },
  numBox: {
    border: '2px solid #026937',
    width: '15px',
    height: '65px',
    padding: '16px',
    position: 'relative',
  },
  singleNumber: {
    fontSize: '30px',
    alignItems: 'center',
    color: '#000000',
    position: 'absolute',
    left: '50%',
    top: '25%',
    transform: 'translate(-50%, -115%)',
    fontFamily: 'Rift Soft',
    fontWeight: 'bold',
  },
  numEndText: {
    display: 'flex',
    position: 'relative',
  },
  notLikely: {
    paddingLeft: 0,
    fontSize: '10px',
    fontWeight: 800,
    paddingTop: '11px',
    marginRight: 'auto',
    marginLeft: 0,
  },
  veryLikely: {
    paddingLeft: 'auto',
    paddingRight: 0,
    fontSize: '10px',
    fontWeight: 800,
    paddingTop: '11px',
    marginRight: 0,
    marginLeft: 'auto',
  },
  submitDiv: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 'fit-content',
    marginTop: '20px',
  },
  submitButtonStyle: {
    background: '#F0CBA7 !important',
    border: '2px solid #EA7200 !important',
    boxShadow: '3px 3px 0px #f0cba7, inset 3px 3px 0px #ffffff',
    fontFamily: 'Rift Soft',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '13px',
    lineHeight: '25px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '0.22px',
    color: '#026937 !important',
    padding: '6px 34px !important',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));
