import React from 'react';
import { Typography } from '@material-ui/core';

import { Appointment } from 'context/appointments/appointmentsContext/AppointmentsContext.types';
import { Place } from 'api/types/address';
import { useStyles } from '../AppointmentDetails.styles';

export const AppointmentLocation = ({ appointment }: { appointment: Appointment }) => {
  const styles = useStyles();
  return (
    <>
      <div>
        <Typography variant="body2" className={styles.detailsTitle}>
          Starting address
        </Typography>
        <Typography variant="body1">
          <AddressFormatted location={appointment.origin} />
        </Typography>
      </div>
      <div>
        <Typography variant="body2" className={styles.detailsTitle}>
          Destination address
        </Typography>
        <Typography variant="body1">
          <AddressFormatted location={appointment.destination} />
        </Typography>
      </div>
    </>
  );
};

export const AddressFormatted = ({
  location: { id, address, address2, city, state, postal, country },
}: {
  location: Place;
}) => (
  <>
    {id === 0 ? (
      '-'
    ) : (
      <>
        <span>{[address, address2].filter(Boolean).join(', ')}</span>
        <br />
        <span>{[city, postal, state, country].filter(Boolean).join(', ')}</span>
      </>
    )}
  </>
);
