import React from 'react';
import clsx from 'clsx';

import { Loader } from 'ui/loader/Loader';

import { ButtonStyled, useStyles } from './Button.styles';
import { ButtonProps } from './Button.types';

export const Button = React.forwardRef(({ buttonType, isLoading, children, ...props }: ButtonProps, ref) => {
  const styles = useStyles(props.size ? props.size : 'medium');
  let variant: 'text' | 'outlined' | 'contained' | undefined;
  let color: 'inherit' | 'default' | 'primary' | 'secondary' | undefined;

  switch (buttonType) {
    case 'twoTone':
      break;
    case 'filled':
      variant = 'contained';
      color = 'secondary';
      break;
    case 'outlined':
      variant = 'outlined';
      break;
    case 'text':
      variant = 'text';
      break;
  }

  return (
    <div className={styles.buttonContainer}>
      <ButtonStyled
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        className={clsx(buttonType === 'twoTone' ? styles.twoTone : '', props.className)}
        variant={variant}
        type={props.type ? props.type : 'button'}
        color={color}
        buttonRef={ref}
        disableRipple
        disableElevation
      >
        <div className={clsx(buttonType === 'twoTone' && styles.twoToneContent)}>{children}</div>
      </ButtonStyled>
      {!!isLoading && (
        <div className={styles.loader}>
          <Loader size="small" />
        </div>
      )}
    </div>
  );
});
