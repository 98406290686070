import { AddressOptionsStateType } from '../addressOptionsContext/AddressOptionsContext.types';

import { AddressOptionsActions, SET_ADDRESS_OPTIONS } from './AddressOptionsContextReducer.types';

export const AddressOptionsContextReducer = (
  state: AddressOptionsStateType,
  action: AddressOptionsActions,
): AddressOptionsStateType => {
  switch (action.type) {
    case SET_ADDRESS_OPTIONS:
      return {
        ...state,
        addressOptions: action.payload.addressOptions,
        isLoading: false,
      };
    default:
      throw new Error();
  }
};
