import { Action } from 'react-fetching-library';

import { AvailabilityRequest } from './AvailabilityActions.types';

export const checkAvailability = (request: AvailabilityRequest): Action<AvailabilityRequest> => {
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    path: { location_id },
    query: { token },
    payload,
  } = request;
  return {
    method: 'POST',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    endpoint: `/public/website/locations/${location_id}/availability?token=${token}`,
    body: payload,
  };
};
