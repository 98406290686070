import React from 'react';

import { DialogProvider, DialogContextState, DialogContextDispatch } from 'context/dialog/DialogContext';

const useDialogState = () => {
  const context = React.useContext(DialogContextState);

  if (context === undefined) {
    throw new Error('useDialog must be used within a DialogProvider');
  }

  return context;
};

const useDialogDispatch = () => {
  const context = React.useContext(DialogContextDispatch);

  if (context === undefined) {
    throw new Error('useSetDialog must be used within a DialogProvider');
  }

  return context;
};

export { DialogProvider, useDialogState, useDialogDispatch };
