import React, { useCallback, useState, useRef, useEffect } from 'react';
import { Grid, Typography, MenuItem, FormControl, FormHelperText } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';

import { Select } from 'ui/select/Select';
import { Input } from 'ui/input/Input';
import { Button } from 'ui/button/Button';
import { FindAccountPayload } from 'api/actions/findAccount/FindAccountActions.types';
import { getAppointmentCategories } from 'api/types/appointment';
import { validateEmail, validatePhone } from 'utils/validate/Validate';

import { useStyles } from './FindAccountForm.styles';
import { FindAccountFormProps, FindAccountFormData } from './FindAccountForm.types';

export function FindAccountForm({ onSubmit, isDuringRequest }: FindAccountFormProps) {
  const styles = useStyles();
  const { register, handleSubmit, errors, control, clearError } = useForm<FindAccountFormData>({ mode: 'onChange' });
  const [error, setError] = useState('');
  const isMounted = useRef(true);

  const categories = getAppointmentCategories();

  const handleSubmitCallback = useCallback(
    async (body: FindAccountFormData) => {
      const isEmail = validateEmail(body.emailOrPhone);
      const request: FindAccountPayload = isEmail
        ? {
            email: body.emailOrPhone.trim(),
            postal: body.postal.trim(),
            category: { id: body.category },
          }
        : {
            phone: body.emailOrPhone.replace(/\D+/g, ''),
            postal: body.postal.trim(),
            category: { id: body.category },
          };
      const errorMessage = await onSubmit(request);
      if (!isMounted.current) return;
      if (!!errorMessage) {
        setError(errorMessage);
      } else {
        setError('');
      }
    },
    [onSubmit],
  );

  // on un-mount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Grid container className={styles.gridContainer} justify="center" alignItems="center">
      <Grid item className={styles.gridItem}>
        <Typography variant="h1" className={styles.title}>
          Manage Your Appointment
        </Typography>
        <Typography variant="body1" className={styles.description}>
          Enter your details
          <br /> to manage your appointment.
        </Typography>

        <form className={styles.form} onSubmit={handleSubmit(handleSubmitCallback)}>
          <FormControl
            className={styles.formControl}
            error={!!errors.emailOrPhone?.message || !!error}
            onInput={() => {
              setError('');
              clearError('emailOrPhone');
            }}
          >
            <Input
              name="emailOrPhone"
              placeholder="Your e-mail or phone number"
              fullWidth
              inputRef={register({
                required: 'This field is required',
                validate: value => {
                  return (
                    validateEmail(value) || validatePhone(value) || 'Please provide correct phone number or email!'
                  );
                },
              })}
            />
            <div className={styles.helperContainer}>
              <FormHelperText>{errors.emailOrPhone && errors.emailOrPhone.message}</FormHelperText>
              <FormHelperText>{!!error && error}</FormHelperText>
            </div>
          </FormControl>

          <FormControl className={styles.formControl} error={!!errors.category?.message}>
            <Controller
              as={
                <Select fullWidth>
                  <MenuItem value={0} disabled>
                    <span className="select-placeholder">– choose job category –</span>
                  </MenuItem>
                  {Object.entries(categories).map(([key, category]) => {
                    return (
                      <MenuItem value={category.id} key={category.id}>
                        {category.displayName}
                      </MenuItem>
                    );
                  })}
                </Select>
              }
              name="category"
              control={control}
              rules={{ validate: value => (!value ? 'Choose Job category' : true) }}
              defaultValue={0}
            />
            <div className={styles.helperContainer}>
              <FormHelperText>{errors.category && errors.category.message}</FormHelperText>
            </div>
          </FormControl>

          <FormControl className={styles.formControl} error={!!errors.postal?.message}>
            <Input
              name="postal"
              placeholder="Zip/postal code"
              fullWidth
              inputRef={register({
                required: 'This field is required',
              })}
            />
            <div className={styles.helperContainer}>
              <FormHelperText>{errors.postal && errors.postal.message}</FormHelperText>
            </div>
          </FormControl>

          <Button className={styles.button} buttonType="twoTone" size="large" type="submit" isLoading={isDuringRequest}>
            Login
          </Button>
        </form>
      </Grid>
    </Grid>
  );
}
