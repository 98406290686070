import { useParameterizedQuery } from 'react-fetching-library';
import { useCallback } from 'react';
import { format } from 'date-fns';

import { Appointment } from 'context/appointments/appointmentsContext/AppointmentsContext.types';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { QuoteRatesRequest, QuoteRatesResponse } from 'api/actions/quoteRates/QuoteRatesActions.types';
import { fetchQuoteRates } from 'api/actions/quoteRates/QuoteRatesActions';
import { useAsyncError } from 'hooks/useAsyncError/useAsyncError';

export function useQuoteRates() {
  const { locationId, token, accountId } = useAuthState();
  const throwError = useAsyncError();
  const { query: fetchQuoteRatesQuery } = useParameterizedQuery<QuoteRatesResponse>(fetchQuoteRates);
  return useCallback(
    async (appointment: Appointment, date: Date) => {
      if (token && locationId && accountId) {
        const request: QuoteRatesRequest = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          path: { location_id: locationId, account_id: accountId },
          query: { token: token },
          payload: {
            category: { id: appointment.category.id },
            date: format(date, 'yyyy-MM-dd'),
            origin: appointment.origin,
            destination: appointment.destination,
            hunks: appointment.hunks,
          },
        };
        const { error, payload } = await fetchQuoteRatesQuery(request);
        if (!error && payload?.rates[0]) {
          return payload.rates[0];
        } else {
          throwError(`Error on: QuoteRatesRequest, ${payload?.meta.status.description}`);
        }
      }
      return undefined;
    },
    [accountId, fetchQuoteRatesQuery, locationId, throwError, token],
  );
}
