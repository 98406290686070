import { createContext } from 'react';

import { AppointmentsStateType, AppointmentDispatchType } from './AppointmentsContext.types';

export const appointmentsStateDefault: AppointmentsStateType = {
  appointments: [],
  finishedAppointments: [],
  upcomingAppointments: [],
  isLoading: true,
  reload: false,
};

export const AppointmentsStateContext = createContext<AppointmentsStateType | undefined>(undefined);
export const AppointmentsDispatchContext = createContext<AppointmentDispatchType | undefined>(undefined);
