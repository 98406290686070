import React from 'react';
import { Typography } from '@material-ui/core';
import Scrollbars from 'react-custom-scrollbars';

import { Button } from 'ui/button/Button';
import { Appointment } from 'context/appointments/appointmentsContext/AppointmentsContext.types';
import { Prices } from 'app/prices/Prices';
import { useDialogDispatch } from 'hooks/useDialog/useDialog';

import { useStyles } from './AppointmentPricingButton.styles';

export const AppointmentPricingButton = ({ appointment }: { appointment: Appointment }) => {
  const styles = useStyles();
  const setDialog = useDialogDispatch();

  const handleOpenDialog = () => {
    setDialog({
      open: true,
      dialogContent: (
        <Scrollbars
          autoHeight
          autoHeightMin="100%"
          autoHeightMax="100%"
          autoHide
          autoHideTimeout={800}
          autoHideDuration={200}
        >
          <Prices appointment={appointment} jobDate={appointment.startDate} />
        </Scrollbars>
      ),
    });
  };

  return (
    <>
      <Button buttonType="text" onClick={() => handleOpenDialog()} className={styles.pricingButton} disableRipple>
        <Typography variant="body1" color="secondary">
          See pricing
        </Typography>
      </Button>
    </>
  );
};
