import React from 'react';
import clsx from 'clsx';

import { ToggleButtonStyled, useStyles } from './ToggleButton.styles';
import { ToggleButtonProps } from './ToggleButton.types';

export const ToggleButton = React.forwardRef(({ children, size, ...props }: ToggleButtonProps, ref) => {
  const styles = useStyles();

  return (
    <ToggleButtonStyled
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      buttonRef={ref}
      disableRipple
      disableFocusRipple
      classes={{
        root: clsx(size === 'large' && styles.large),
      }}
    >
      {children}
    </ToggleButtonStyled>
  );
});
