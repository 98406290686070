import { Action } from 'react-fetching-library';

import {
  AppointmentsRequest,
  UpdateAppointmentRequest,
  RescheduleAppointmentRequest,
  ConfirmAppointmentRequest,
  ReviewAppointmentRequest,
  ReviewAppointmentPlatformClickRequest,
  RequestEtaRequest,
} from './AppointmentsActions.types';

export const fetchAppointments = (request: AppointmentsRequest): Action<AppointmentsRequest> => {
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    path: { location_id, account_id },
    query: { token },
  } = request;
  return {
    method: 'GET',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    endpoint: `/public/website/locations/${location_id}/accounts/${account_id}/appointments?token=${token}`,
  };
};

export const updateAppointment = (request: UpdateAppointmentRequest): Action<UpdateAppointmentRequest> => {
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    path: { location_id, account_id, appointment_id },
    query: { token },
    payload,
  } = request;
  return {
    method: 'PUT',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    endpoint: `/public/website/locations/${location_id}/accounts/${account_id}/appointments/${appointment_id}/?token=${token}`,
    body: payload,
  };
};

export const rescheduleAppointment = (request: RescheduleAppointmentRequest): Action<RescheduleAppointmentRequest> => {
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    path: { location_id, account_id, appointment_id },
    query: { token },
    payload,
  } = request;

  return {
    method: 'POST',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    endpoint: `/public/website/locations/${location_id}/accounts/${account_id}/appointments/${appointment_id}/reschedule?token=${token}`,
    body: payload,
  };
};

export const confirmAppointment = (request: ConfirmAppointmentRequest): Action<ConfirmAppointmentRequest> => {
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    path: { location_id, account_id, appointment_id },
    query: { token },
    payload,
  } = request;

  return {
    method: 'PUT',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    endpoint: `/public/website/locations/${location_id}/accounts/${account_id}/appointments/${appointment_id}/?token=${token}`,
    body: payload,
  };
};

export const reviewAppointment = (request: ReviewAppointmentRequest): Action<ReviewAppointmentRequest> => {
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    path: { location_id, account_id, appointment_id },
    query: { token },
    payload,
  } = request;

  return {
    method: 'PUT',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    endpoint: `/public/website/locations/${location_id}/accounts/${account_id}/appointments/${appointment_id}/reviews?token=${token}`,
    body: payload,
  };
};

export const reviewAppointmentPlatformClick = (
  request: ReviewAppointmentPlatformClickRequest,
): Action<ReviewAppointmentPlatformClickRequest> => {
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    path: { location_id, account_id, appointment_id },
    query: { token },
    payload,
  } = request;

  return {
    method: 'POST',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    endpoint: `/public/website/locations/${location_id}/accounts/${account_id}/appointments/${appointment_id}/reviews/clicks?token=${token}`,
    body: payload,
  };
};

export const requestEta = (request: RequestEtaRequest): Action<RequestEtaRequest> => {
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    path: { location_id, account_id, appointment_id },
    query: { token },
    payload,
  } = request;

  return {
    method: 'POST',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    endpoint: `/public/website/locations/${location_id}/accounts/${account_id}/appointments/${appointment_id}/dispatchmessage?token=${token}`,
    body: payload,
  };
};
