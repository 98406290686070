import { Grid, Typography, Hidden } from '@material-ui/core';
import React from 'react';
import LocalShipping from '@material-ui/icons/LocalShipping';

import { Button } from 'ui/button/Button';
import { useDialogDispatch } from 'hooks/useDialog/useDialog';

import { useStyles } from './CheckEtaSentDialog.styles';

export function CheckEtaSentDialog() {
  const styles = useStyles();
  const setDialog = useDialogDispatch();

  const closeDialog = () => {
    setDialog({ open: false });
  };

  return (
    <Grid container direction="row" alignItems="center" justify="center" spacing={4} className={styles.dialogContainer}>
      <Grid item>
        <Typography variant="h2">Your ETA request has been sent!</Typography>
      </Grid>

      <Hidden smDown>
        <Grid item>
          <LocalShipping className={styles.checkIcon} />
        </Grid>
      </Hidden>

      <Grid item>
        <Typography variant="body1" color="primary" className={styles.dialogDescription}>
          We will be reaching out to you shortly with more information.
        </Typography>
      </Grid>
    </Grid>
  );
}
