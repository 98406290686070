const regex = /^(\d+)?\s*([^,]+),?/i;

export const parseAddress = (address: string) => {
  const match = address.match(regex);

  const components: { houseNumber?: string; street?: string } = {};

  if (match) {
    components.houseNumber = match[1]?.trim();
    components.street = match[2]?.trim();
  }

  return components;
};
